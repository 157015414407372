import React, { useEffect, useState } from 'react';
import OEButton, { ButtonSize } from '../../../core/components/form/OEButton';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import MSFilterSelectionItem from './MSFilterSelectionItem';

interface IComponentInfo {
    filters: IMicroStrategyFilter[];
    updateFilterValues: (f: IMicroStrategyFilter) => void;
    filterReport?: () => void;
    filterId: string;
}

const MSFilterSelection: React.FunctionComponent<IComponentInfo> = ({
    filters, updateFilterValues, filterReport, filterId
}) => {

    const [localFilters, setLocalFilters] = useState<IMicroStrategyFilter[]>([]);

    useEffect(() => {
        const f: IMicroStrategyFilter[] = [...filters];
        f.sort(function (x, y) {
            return x.sortOrder - y.sortOrder;
        });

        setLocalFilters(f.filter(q => !q.hidden));
    }, [filters]);

    const updateFilters = (f: IMicroStrategyFilter) => {
        let i: IMicroStrategyFilter;
        if (f.key === '') {
            i = localFilters.filter(q => q.name === f.name)[0]
        }
        else {
            i = localFilters.filter(q => q.key === f.key)[0]
        }
        i.value = f.value;
        i.name = f.name;
        i.displayValue = f.displayValue;
        updateFilterValues(f);
    }

    return (
        <>
            <div className="report-filters">
                {localFilters.map((item, index) =>
                    <MSFilterSelectionItem
                        filter={item}
                        key={index}
                        setValue={updateFilters}
                        filterId={filterId}
                    />
                )}

                {filterReport && (
                    <div className="col report-filters-page3">
                        <OEButton bSize={ButtonSize.Small} onClick={filterReport} text="Apply" />
                    </div>
                )}
            </div>
            <div className="cleardiv" />
        </>
    );
};

export default MSFilterSelection;
