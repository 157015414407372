import React, { useEffect, useState } from 'react';
import { IBreadcrumb } from '../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../core/components/breadcrumbs/OEBreadcrumb';
import { defaultConfirmationMessage, IConfirmationMessage } from "../../core/components/messaging/entities/ConfirmationMessage";
import OEConfirmation from "../../core/components/messaging/OEConfirmation";
import OESpinner from '../../core/components/messaging/OESpinner';
import { ISortData } from '../../core/components/sort/entities/SortData';
import OESort from '../../core/components/sort/OESort';
import { ColumnType, IColumn, ModifiedColumns } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { parseQueryString, updateURLParameter } from '../../core/utilities/URL';
import { defaultMenu, defaultMenuLookupTables, getMenuList, IMenu, IMenuLookupTables } from '../entities/Menu';
import { useDeleteMenu, useGetMenus, usePutMenu } from '../services/MenuService';
import { useGetRoutes } from '../services/RouteService';
import { ISelectedSiteAdminInfo } from '../SiteAdmin';
import MenuFormik from './MenuFormik';
import MenuPermissions from './MenuPermissions';
import OETabs, { OETab } from '../../core/components/tabs/OETabs';
import { formatInteger } from '../../core/utilities/String';

enum ModalTypes {
    None = 1,
    Edit,
    Sort,
    Permissions,
}

const Menus: React.FunctionComponent<ISelectedSiteAdminInfo> = () => {
    const params: any = parseQueryString();

    const { service } = useGetMenus();
    const { service: routeService } = useGetRoutes();
    const { service: putService, setMenu: setPutMenu } = usePutMenu();
    const { service: deleteService, setMenuId: setDeleteId } = useDeleteMenu();
    const { service: activeService, setMenu: setActiveServiceItem } = usePutMenu();

    const [lookupTables, setLookupTables] = useState<IMenuLookupTables>(defaultMenuLookupTables);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const rootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Root Menus' }];
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const [items, setItems] = useState<IMenu[]>([]);
    const [filtered, setFiltered] = useState<IMenu[]>([]);
    const [active, setActive] = useState<IMenu[]>([]);
    const [inactive, setInactive] = useState<IMenu[]>([]);
    const [parentId, setParentId] = useState<number>(parseInt(params.parentid, 10) || 0);
    const [item, setItem] = useState<IMenu>(defaultMenu);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [reloading, setReloading] = useState(false);
    const [sortList, setSortList] = useState<ISortData[]>([]);
    const [saveList, setSaveList] = useState<IMenu[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState('active');

    useEffect(() => {
        if (service.result) {
            setItems(getMenuList(service.result));
        }
    }, [service]);

    useEffect(() => {
        if (activeService.isFinished && !activeService.isSuccess) {
            setErrors([activeService.response.message] || ['An error occurred updating the active status']);
        }
    }, [activeService]);

    useEffect(() => {
        if (routeService.result) {
            setLookupTables({ ...defaultMenuLookupTables, routes: routeService.result.items });
        }
    }, [routeService]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            setReloading(true);
        }
    }, [deleteService]);

    useEffect(() => {
        if (putService.isFinished) {
            if (putService.isSuccess) {
                saveList.shift();
                setSaveList([...saveList]);
                if (saveList.length === 0) {
                    setReloading(true);
                }
            } else {
                setErrors([putService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [putService]);

    useEffect(() => {
        if (items.length > 0) {
            onFilter(items);
            updateBreadcrumbs(parentId);
        }
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        setActive(filtered.filter(q => q.isActive));
        setInactive(filtered.filter(q => !q.isActive));
        // eslint-disable-next-line
    }, [filtered]);

    useEffect(() => {
        if (items.length > 0) {
            onFilter(items);
            updateURLParameter('parentid', parentId);
            updateBreadcrumbs(parentId);
        }
        // eslint-disable-next-line
    }, [parentId]);

    useEffect(() => {
        if (saveList.length > 0) {
            setPutMenu(saveList[0]);
        } else {
            setShowModal(ModalTypes.None);
        }
        // eslint-disable-next-line
    }, [saveList]);

    useEffect(() => {
        if (reloading) {
            window.location.reload();
        }
    }, [reloading]);

    const onFilter = (s: IMenu[]) => {
        setFiltered([...s.filter(q => q.parentId === parentId)]);
    }

    const onSort = () => {
        setErrors([]);
        if (filtered) {
            const list: ISortData[] = [];
            for (const item of active) {
                list.push({ id: item.id, name: item.name, sortOrder: item.sortOrder });
            }
            setSortList(list);
            setShowModal(ModalTypes.Sort);
        }
    };

    const onSortOK = (d: ISortData[]) => {
        const l: IMenu[] = [];
        for (const m of d) {
            const m2 = items.filter(q => q.id === m.id)[0];
            if (m2.sortOrder !== m.sortOrder) {
                l.push({ ...m2, sortOrder: m.sortOrder });
            }
        }
        setSaveList(l);
        if (l.length === 0) {
            setShowModal(ModalTypes.None);
        }
    };

    const onSave = () => {
        setShowModal(ModalTypes.None);
        setReloading(true);
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        setItem({ ...defaultMenu, parentId, sortOrder: filtered.length + 1 });
        setShowModal(ModalTypes.Edit);
    };

    const onPermissions = (i: IMenu) => {
        setItem(i);
        setShowModal(ModalTypes.Permissions);
    };

    const onEdit = (i: IMenu) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
    };

    const onAddChild = (i: IMenu) => {
        onNavigate(i, true);
        setItem({ ...defaultMenu, parentId: i.id, sortOrder: filtered.length + 1 });
        setShowModal(ModalTypes.Edit);
    };

    const onConfirmDelete = (i: IMenu) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Menu?",
            message: `Are you sure you want to delete the menu <b>${i.name}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const onUpdateActive = (i: IMenu) => {
        i.isActive = !i.isActive;
        setActiveServiceItem({ ...i });
    };

    const onConfirmActive = (i: IMenu) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i, show: true, title: "Update Active Status?",
            message: `Are you sure you want change the active status for the report <b>${i.title}</b>?`,
            onOk: onUpdateActive, onCancel: onCancel
        });
    };

    const onNavigate = (i: IMenu, add?: boolean) => {
        setParentId(i.id);
        setItem({ ...defaultMenu, parentId: i.id, sortOrder: i.childCount + 1 });
        add && setShowModal(ModalTypes.None);
    };

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        setParentId(i.id);
    };

    const updateBreadcrumbs = (pid: number, breadcumbs?: IBreadcrumb[]) => {
        let b: IBreadcrumb[] = breadcumbs || [];
        if (pid > 0) {
            let i: IMenu = items.filter(q => q.id === pid)[0];
            b = [{ id: i.id, name: i.name }].concat(b);
            updateBreadcrumbs(i.parentId, b);
        }
        else {
            b = rootBreadcrumbs.concat(b);
            setBreadcrumbs(b);
        }
    }

    const actions: IColumnAction[] = [
        { icon: Icon.Permissions, onClick: onPermissions, helpText: 'Update Permissions' },
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Add, onClick: onAddChild, helpText: 'Add Child Menu' },
        { icon: Icon.CheckYes, condition: 'isActive', onClick: onConfirmActive, helpText: `Deactivate` },
        { icon: Icon.CheckNo, condition: 'isActive', notCondition: true, onClick: onConfirmActive, helpText: `Activate` },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "childCount", notCondition: true, helpText: 'Delete' },
        { icon: Icon.ChildrenNavigate, onClick: onNavigate, helpText: 'View Child Menus', condition: "childCount" },
    ];
    
    const inActiveActions: IColumnAction[] = [
        { icon: Icon.CheckYes, condition: 'isActive', onClick: onConfirmActive, helpText: `Deactivate` },
        { icon: Icon.CheckNo, condition: 'isActive', notCondition: true, onClick: onConfirmActive, helpText: `Activate` },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "childCount", notCondition: true, helpText: 'Delete' },
        { icon: Icon.ChildrenNavigate, onClick: onNavigate, helpText: 'View Child Menus', condition: "childCount" },
    ];


    const columns: IColumn[] = [
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions },
        { id: 'name', idNewLine: 'id', name: 'Name', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Menu' },
        { id: 'sortOrder', width: "20px", name: 'Order', sort: true, type: ColumnType.Integer, className: 'text-center' },
        { id: 'path', name: 'Path', type: ColumnType.String },
        { id: 'childCount', width: "20px", name: 'Child Menus', sort: true, type: ColumnType.Integer, className: 'text-center' },
        ...ModifiedColumns,
    ];

    const inactiveColumns: IColumn[] = [
        { actions: inActiveActions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions },
        { id: 'name', idNewLine: 'id', name: 'Name', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Menu' },
        { id: 'path', name: 'Path', type: ColumnType.String },
        { id: 'childCount', width: "20px", name: 'Child Menus', sort: true, type: ColumnType.Integer, className: 'text-center' },
        ...ModifiedColumns,
    ];

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OEBreadcrumb breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} navigateBreadcrumb={onNavigateBreadcrumb} />
            <OESpinner message="Saving Menus" hidden={!putService.isInProgress} />
            <OESpinner message="Re-loading Menus" hidden={!reloading} />
            <OESpinner message="Deleting Menu" hidden={!deleteService.isInProgress} />
            {!putService.isInProgress && !reloading && (
                <>
                    <OETabs className="fst-italic small" id="menu" defaultTab={activeTab} onSelect={setActiveTab}>
                        <OETab title={`Active (${formatInteger(active.length)})`} eventKey="active">
                            <OETable
                                loading={reloading || service.isInProgress}
                                loadingMessage="Loading Site Menus"
                                data={active}
                                columns={columns}
                                showPagination={true}
                                defaultSort="sortOrder"
                                defaultPageSize={25}
                                actions={[
                                    { icon: Icon.Add, text: 'Add New Menu', action: onAdd },
                                    { hidden: filtered.length < 2, icon: Icon.Sort, text: 'Sort Menus', action: onSort },
                                ]}
                            />
                        </OETab>
                        <OETab title={`Inactive (${formatInteger(inactive.length)})`} eventKey="inactive">
                            <OETable
                                loading={reloading || service.isInProgress}
                                loadingMessage="Loading Site Menus"
                                data={inactive}
                                columns={inactiveColumns}
                                showPagination={true}
                                defaultSort="sortOrder"
                                defaultPageSize={25}
                            />
                        </OETab>
                    </OETabs>
                </>
            )}

            {showModal === ModalTypes.Permissions && (
                <MenuPermissions onCancel={onCancel} item={item} />
            )}
            {showModal === ModalTypes.Edit && (
                <MenuFormik parentId={parentId} lookupTables={lookupTables} onCancel={onCancel} onSave={onSave} item={item} />
            )}
            {showModal === ModalTypes.Sort && (
                <OESort show errors={errors} items={sortList} title={`Sort Menus`} onOk={onSortOK} onCancel={onCancel} />
            )}
        </>
    );
};

export default Menus;
