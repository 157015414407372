import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OEMessage from '../core/components/messaging/OEMessage';
import { ICoreComponentInfo } from '../site/components/OECoreComponent';
import { appRootBreadcrumbs } from '../site/branding/entities/CustomRouteProperties';
import WidgetContent from '../widgets/components/WidgetContent';
import { defaultWidget, getWidgetList, IWidget } from '../widgets/entities/Widget';
import { getWidgetGroupList } from '../widgets/entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../widgets/services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../widgets/services/WidgetService';
import './styles/widget.css';

interface IComponentInfo {
    code?: string;
}

const Widget: React.FunctionComponent<ICoreComponentInfo & IComponentInfo> = ({ routeProperties, code }) => {
    const { service: groupService } = useGetWidgetGroupByCode('WIDGETPAGE');
    const { service, setGroupId } = useGetWidgetsByGroupId();
    const [items, setItems] = useState<IWidget[]>([]);
    const [item, setItem] = useState<IWidget>(defaultWidget);
    const [searchParams] = useSearchParams();
    const [widgetId, setWidgetId] = useState<string>(searchParams.get("id") || 'NOT-FOUND');

    useEffect(() => {
        setWidgetId(searchParams.get("id") || 'NOT-FOUND')
    }, [searchParams]);

    useEffect(() => {
        code && setWidgetId(code);
    }, [code]);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
        // eslint-disable-next-line
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
        // eslint-disable-next-line
    }, [service]);


    useEffect(() => {
        const f = items.find(q => q.code === widgetId);
        if (f) {
            setItem(f);
        }
        // eslint-disable-next-line
    }, [items, widgetId]);

    useEffect(() => {
        routeProperties.setPageBreadcrumbs?.(item.name, [...appRootBreadcrumbs, { id: 1, name: item.name }]);
        // eslint-disable-next-line
    }, [item]);

    return (
        <>
            <div className='p30'>
                {items.filter(q => q.code === widgetId).length > 0 && (
                    <WidgetContent item={item} />
                )}
                {items.filter(q => q.code === widgetId).length === 0 && (
                    <OEMessage message="Widget Not Found" />
                )}
            </div>
        </>
    );
};

export default Widget;