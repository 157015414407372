import React, { useState } from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { Icon } from '../../../core/entities/Icon';
import ReportDataDownload from '../../../reporting/components/common/ReportDataDownload';
import ReportDescription from '../../../reporting/components/common/ReportDescription';
import ReportFlagging from '../../../reporting/components/report-flag/ReportFlagging';
import { IReport } from '../../../reporting/entities/Report';
import { ReportDataDownloadType } from '../../../reporting/entities/ReportConfiguration';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';

interface IReportTitle {
    report: IReport;
    flagging: boolean;
    dataDownload: ReportDataDownloadType;
    pageFilters: IMicroStrategyFilter[];
}

const ReportTitle: React.FunctionComponent<IReportTitle> = ({ report, flagging, dataDownload, pageFilters }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const onShowDescription = () => {
        setShowDescription(true);
    }
    const onHideDescription = () => {
        setShowDescription(false);
    }

    return (
        <>
            <h4 className="title-style">{report.title}</h4>
            {report.description && report.description.length > 0 && (
                <OEIcon
                    onClick={onShowDescription}
                    className=" report-description-link m-l-5"
                    icon={Icon.ReportDescriptionLink}
                    title={`${report.title} Description and Information`}
                />
            )}

            {flagging && (
                <ReportFlagging report={report} pageFilters={pageFilters} />
            )}

            {showDescription && (
                <ReportDescription
                    title={report.title}
                    description={report.description}
                    onCancel={onHideDescription}
                />
            )}

            {dataDownload !== ReportDataDownloadType.DownloadOff && (
                <ReportDataDownload
                    dataDownload={dataDownload}
                    report={report}
                    pageFilters={pageFilters}
                />
            )}
        </>
    );
}



export default ReportTitle;
