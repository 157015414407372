import React, { useEffect, useState } from 'react';
import OELink from '../../core/components/general/OELink';
import { Icon } from '../../core/entities/Icon';
import { reactLogout } from '../../core/services/Authentication';
import { getSiteSetting, saveSiteSetting, SiteLanguages, SiteSetting } from '../../site/entities/SiteSettings';
import { defaultProfile, IProfile } from '../../user/entities/Profile';
import { defaultProfileImage, getImageSource, IProfileImage } from '../../user/entities/ProfileImage';
import { useGetProfileImage } from '../../user/services/ProfileImageService';
import { useGetProfile } from '../../user/services/ProfileService';
import { useLogout } from '../services/SecurityService';

interface IComponentInfo {
    className?: string;
    responsive?: boolean;
}

const UserProfile: React.FunctionComponent<IComponentInfo> = ({ className, responsive }) => {
    const { service: profileService } = useGetProfile();
    const { service: imageService } = useGetProfileImage();
    const { service: logoutService, logout: processLogout } = useLogout();

    const [profile, setProfile] = useState<IProfile>(defaultProfile);
    const [profileImage, setProfileImage] = useState<IProfileImage>(defaultProfileImage);
    const [language, setLanguage] = useState<string>(getSiteSetting(SiteSetting.TranslateLanguage));

    const handleTranslateClick = (lang: string) => {
        if (lang !== language) {
            translateLanguage(lang);
            saveSiteSetting(SiteSetting.TranslateLanguage, lang);
            setLanguage(lang);
        }
    };

    const translateLanguage = (lang: string) => {
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveSiteSetting(SiteSetting.TranslateLanguage, lang);
        }
        if (googleTranslateDropdown && !googleTranslateDropdown.value && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveSiteSetting(SiteSetting.TranslateLanguage, lang);
        }
    }

    useEffect(() => {
        if (imageService.result) {
            setProfileImage(imageService.result);
        }
    }, [imageService]);

    useEffect(() => {
        if (profileService.result) {
            setProfile(profileService.result);
        }
    }, [profileService]);

    useEffect(() => {
        if (logoutService.result !== undefined) {
            reactLogout();
        }
        // eslint-disable-next-line
    }, [logoutService]);

    return (
        <>
            {!profile.userName && (
                <div className="text-end d-flex m-r-20" style={{ alignItems: 'start', height: 40 }} onClick={() => handleTranslateClick(language === SiteLanguages.English ? SiteLanguages.Spanish : SiteLanguages.English)}>
                    <img src={'images/lang_translator.png'} alt="mdo" width="30" height="30" style={{ marginRight: 10 }} />
                    <p className="skip-to-content" style={{ textDecoration: 'underline', color: '#479BD7' }} >
                        {language === "en" ? `Translate to Espanol` : "Translate to Inglesa"}
                    </p>
                </div>
            )
            }
            <div className={`dropdown text-end ${className || ''}`}>
                {profile.userName && (
                    <>
                        <OELink className="d-block text-decoration-none dropdown-toggle link-dark" id="dropdownUser1" dataBsToggle="dropdown" ariaExpanded={false}>
                            <img src={getImageSource(profileImage)} alt="mdo" width="32" height="32" className="rounded-circle" />
                            {!responsive && (
                                <span className="m-l-5 fs-6 link-dark">{`${profile.name} ${profile.surname}`}</span>
                            )}
                        </OELink>
                        <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                            <li><OELink href="/userprofile" className="dropdown-item" >Profile</OELink></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><OELink href="/about" className="dropdown-item" >About</OELink></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><OELink className="dropdown-item" onClick={processLogout} >Log out</OELink></li>
                        </ul>
                    </>
                )}
                {!profile.userName && (
                    <OELink
                        text="Login"
                        href="/Account/Login"
                        icon={Icon.Login}
                        className="d-block text-decoration-none link-dark"
                        ariaExpanded={false}
                    />
                )}

            </div>
        </>
    );
};

export default UserProfile;

