import React, { useRef } from 'react';
import Slider from 'react-slick';
import { ICarousalInfo } from './entities/Carousal';
import '../../../css/custom.css';


function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div className={className}
            style={{ ...style, display: "block", background: "black" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, background: "black" }}
            onClick={onClick}
        />
    );
}


const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    dots: true,
    infinite: true,
    speed: 500,
    slickGoTo: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 760,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
    ],
};

const Carousal: React.FunctionComponent<ICarousalInfo> = ({ items, setSelectedItem, isDetail, selectedItem, onClickItem }) => {

    const sliderRef = useRef<Slider>(null);

    const displayImages = items?.map((title, i) => (
        <img src={title.image} alt="slider" key={i} className="container" />
    ));

    const displayIcon = items?.map((item, i) => (
        <div className="slick-display-item" onClick={() => {
            if (selectedItem === i) {
                onClickItem(i); 
            } else {
                sliderRef?.current?.slickGoTo(i);
            }
        }} key={i} style={{ backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', fontSize: 12 }}>
                {item.image && <img src={item.image} alt="slider" key={i} className="container" />}
            {item.title}
        </div>
    ));
   
    return (
        <section className="slick-container">
            <Slider ref={sliderRef} initialSlide={selectedItem as number} {...settings} afterChange={setSelectedItem}>
                {isDetail ? displayIcon : displayImages}
            </Slider>
        </section>
    );
};

export default Carousal;
