/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import OESpinner, { SpinnerStyle } from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { emptyGUID, getEmptyGUID } from '../../../core/utilities/String';
import { refreshPage } from '../../../core/utilities/URL';
import { canDebugReport } from '../../../reporting/entities/Report';
import { ProcessingPage, ProcessingPageFilters, getProcessingPageFiltersLabel, logProcessingPageFilters } from '../../../reporting/entities/ReportProcessing';
import { SiteReportIds } from '../../../reporting/entities/ReportType';
import { ISiteReport, defaultSiteReport } from '../../../reporting/entities/SiteReport';
import { useGetEmbeddedReportFilters } from '../../../reporting/services/ReportFilterService';
import { useGetSiteReport, useGetSiteReportFilters } from '../../../reporting/services/SiteReportService';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { IReportFilter, getFiltersFromReportFilters, getReportFilterFromJSON, getReportFilterList } from '../../entities/ReportFilter';
import MSFilterSelection from './MSFilterSelection';

interface IPageFiltersInfo {
    id: string;
    filterConfiguration: string;
    setNotification: (n: INotification) => void;
    pageProcessingStep: ProcessingPage;
    setPageProcessingStep: (i: ProcessingPage) => void;
    setPageFilters: (i: IMicroStrategyFilter[]) => void;
    setDebugMessage?: (i: string) => void;
    initialValues?: string;
    hidden?: boolean;
}

const PageFilters: React.FunctionComponent<IPageFiltersInfo> = ({
    setNotification, setPageProcessingStep, setPageFilters, filterConfiguration,
    pageProcessingStep, setDebugMessage, initialValues, hidden, id
}) => {
    const { service: embeddedFilterService, doRefresh: loadBaseFilters } = useGetEmbeddedReportFilters(false); // Step 1 - get base filters for all objects
    const { service: siteReportService, setId: setSiteReportId } = useGetSiteReport(''); // Step 2 - get the site report inormation
    const { service: siteReportFilterService, setReport: setSiteReportFilter } = useGetSiteReportFilters(); // Step 3 - get filters for page

    const [processingStep, setProcessingStep] = useState<ProcessingPageFilters>(ProcessingPageFilters.Idle);
    const [siteReport, setSiteReport] = useState<ISiteReport>(defaultSiteReport);

    const [siteFilters, setSiteFilters] = useState<IReportFilter[]>([]);
    const [baseFilters, setBaseFilters] = useState<IReportFilter[]>([]);
    const [filters, setFilters] = useState<IMicroStrategyFilter[]>([]);
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);

    const [debug] = useState<boolean>(canDebugReport);
    const [message, setMessage] = useState<string>('Loading Page Filters');

    useEffect(() => {
        switch (pageProcessingStep) {
            case ProcessingPage.FatalError:
                setProcessingStep(ProcessingPageFilters.FatalError);
                setMessage('');
                break;
            case ProcessingPage.PageFiltersLoad:
                setProcessingStep(ProcessingPageFilters.Start);
                setMessage('');
                break;
        }
        // eslint-disable-next-line
    }, [pageProcessingStep]);

    useEffect(() => {
        logProcessingPageFilters(processingStep, debug);
        setDebugMessage?.(getProcessingPageFiltersLabel(processingStep));
        if (pageProcessingStep === ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.FatalError);
            setMessage('');
            return;
        }
        switch (processingStep) {
            case ProcessingPageFilters.Start:
                if (baseFilters.length === 0) {
                    loadBaseFilters();
                }
                else {
                    setProcessingStep(ProcessingPageFilters.SiteReportLoad);
                }
                break;

            case ProcessingPageFilters.SiteReportLoad:
                if (emptyGUID(siteReport.id)) {
                    setSiteReportId(SiteReportIds.EmbeddedFilters);
                }
                else {
                    setProcessingStep(ProcessingPageFilters.CreatePageFilters);
                }
                break;

            case ProcessingPageFilters.CreatePageFilters:
                setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
                break;

            case ProcessingPageFilters.SiteReportFiltersLoad:
                setMessage('Loading Page Filters');

                let f: IReportFilter[] = [];
                if (reportFilters.length > 0) {
                    f = reportFilters;
                }
                else {
                    f = getReportFilterFromJSON(filterConfiguration);
                }
                for (const c of f) {
                    c.filterLoaded = false;
                }
                setReportFilters(f.filter(q => !q.dossier));
                setSiteReportFilter({
                    ...siteReport,
                    pageId: getEmptyGUID(),
                    reportFilters: getFiltersFromReportFilters(f.filter(q => !q.dossier), baseFilters, initialValues)
                });
                break;

            case ProcessingPageFilters.SiteReportFiltersComplete:
                for (const i of siteFilters) {
                    let match = reportFilters.find(q => q.name.toLowerCase() === i.name.toLowerCase());
                    if (match) {
                        match.values = i.values;
                        match.value = i.selectedValue;
                    }
                }
                setProcessingStep(ProcessingPageFilters.UpdateFilters);
                break;

            case ProcessingPageFilters.UpdateFilters:
                const d: IMicroStrategyFilter[] = [];
                for (const c of reportFilters) {
                    let s: string = '';
                    if (c.values) {
                        let f = c.values.filter(q => q.id === c.value);
                        if (f && f.length > 0) {
                            s = f[0].parentId;
                        }
                    }
                    d.push({
                        key: c.msId,
                        selection: s,
                        name: c.msName,
                        externalId: c.externalId,
                        pageFilter: true,
                        values: c.values,
                        value: c.value,
                        label: c.label,
                        cascadePriority: c.cascadePriority,
                        sortOrder: c.sortOrder,
                        displayValue: c.value,
                        rangeIncrements: c.rangeIncrements || "2",
                        isSuppression: false,
                        filterType: c.filterType,
                        defaultSelection: c.defaultSelection || '',
                        msKey: c.msKey
                    });
                }
                setPageFilters(d);
                setFilters(d);
                setProcessingStep(ProcessingPageFilters.Complete);
                break;

            case ProcessingPageFilters.Complete:
                setPageProcessingStep(pageProcessingStep === ProcessingPage.PageFiltersLoad ? ProcessingPage.PageFiltersLoaded : ProcessingPage.PageFiltersUpdated);
                setMessage('');
                break;

            case ProcessingPageFilters.FatalError:
                setMessage('')
                setPageFilters([]);
                setPageProcessingStep(ProcessingPage.FatalError);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (embeddedFilterService.result) {
            setBaseFilters(getReportFilterList(embeddedFilterService.result));
        }
        // eslint-disable-next-line
    }, [embeddedFilterService]);

    useEffect(() => {
        if (baseFilters.length > 0) {
            setProcessingStep(ProcessingPageFilters.SiteReportLoad);
        }
        // eslint-disable-next-line
    }, [baseFilters]);

    useEffect(() => {
        if (siteReportService.result) {
            setSiteReport(siteReportService.result);
        }
        // eslint-disable-next-line
    }, [siteReportService]);

    useEffect(() => {
        if (!emptyGUID(siteReport.id) && pageProcessingStep !== ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.CreatePageFilters);
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            if (siteReportFilterService.data) {
                setSiteFilters(siteReportFilterService.data.data);
            }
            else {
                setNotification({ message: `Re-loading Site Filters (PageFilters)`, type: 'error' })
                refreshPage();
            }
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingPageFilters.SiteReportFiltersLoad:
                setProcessingStep(ProcessingPageFilters.SiteReportFiltersComplete);
                break;
        }
        // eslint-disable-next-line
    }, [siteFilters]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingPageFilters.SiteReportFiltersComplete:
                setProcessingStep(ProcessingPageFilters.UpdateFilters);
                break;
        }
        // eslint-disable-next-line
    }, [reportFilters]);

    const updateFilterValues = (f: IMicroStrategyFilter) => {
        const t: IReportFilter = reportFilters.filter(q => q.msKey === f.msKey)[0]
        t.value = f.value;
        t.valueName = f.values.filter(q => q.id === f.value)[0].name;
        setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
    }

    return (
        <span style={{ display: hidden ? 'none' : 'block' }} >
            <MSFilterSelection
                updateFilterValues={updateFilterValues}
                filters={filters}
                filterId={id}
            />
            <OESpinner message={message} oeStyle={SpinnerStyle.Small} />
        </span>
    );
};

export default PageFilters;
