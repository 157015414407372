import React, { Children, cloneElement, useState } from 'react';
import { useNavigate } from 'react-router';
import { IBreadcrumb } from '../../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../../core/components/breadcrumbs/OEBreadcrumb';
import { IDropdownValues } from '../../../core/components/form/OEDropdown';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { navigateToPage, navigateToPageReactRouter } from '../../../core/utilities/URL';
import { IHeaderText } from '../../entities/HeaderText';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';
import { ISchoolOptionsSelection } from '../../../reporting/branding/entities/SchoolOptionsSelection';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';

import SiteFooter from './SiteFooter';
import { Subheader } from './Subheader';

interface IComponentInfo {
    children?: any;
    collapsed: boolean;
    siteMenu: MenuEntity[];
    pathname: string;
    headerText: IHeaderText;
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void;
    width: number;
    schoolDistricts: ISchoolOptionsSelection[];
    onSearchSchoolDistricts: (val: string) => void;
    academicYears: IDropdownValues[];
    closeMenu: () => void;
}

const pagesWithoutScroll = ['/glossary'];

const Body: React.FunctionComponent<IComponentInfo> = ({
    children, siteMenu, pathname = '', routeProperties, width, setRouteProperties,
    onSearchSchoolDistricts, schoolDistricts, academicYears, closeMenu
}) => {

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState<string>('')
    const [year, setYear] = useState<string>(getSiteSetting(SiteSetting.AcademicYear));
    const typeScreen = useResponsivenessContext();

    const onSearch = (val: string) => {
        setInputValue(val)
        onSearchSchoolDistricts(val);
    }

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        navigateToPageReactRouter(navigate, '/home')
    };

    const updateBreadcrumbs = (i: IBreadcrumb[]) => {
        routeProperties.setPageBreadcrumbs?.(routeProperties.pageName, i);
    }

    const onNavigate = (value: string) => {
        navigateToPage(`/kysrc?organization=${value}`)
    }

    const renderHomePage = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className='body-container' id='main-content'
                    style={{
                        width: '100%',
                        overflow: width < 1000 ? 'unset' : 'overlay',
                    }}>

                    {Children.map(children, (child) => {
                        return cloneElement(child, {
                            children: Children.map(child.props.children, (child_2) => {
                                return cloneElement(child_2, {
                                    element: React.isValidElement(child_2.props.element) ? cloneElement(child_2.props.element, { siteMenu, selectedYear: getSiteSetting(SiteSetting.AcademicYear) }) : child_2.props.element
                                })
                            })
                        })
                    })}
                </div >
            </div>
        )
    }


    const renderAuxiliaryPages = () => {
        return (
            <div
                onClick={closeMenu}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: typeScreen !== "web" ? '100%' : 'calc(100% - 290px)'
                }}>
                <div className='body-container' id='main-content'
                    style={{
                        width: '100%',
                        overflow: (pagesWithoutScroll.includes(pathname) || width < 1000) ? 'unset' : 'overlay',
                        height: width < 1000 ? 'auto' : '100%'
                    }}>
                    {routeProperties.pageName && (
                        <div className="breadcumb-container">
                            <OEBreadcrumb
                                isBlack={false}
                                breadcrumbs={routeProperties.breadcrumbs}
                                setBreadcrumbs={updateBreadcrumbs}
                                navigateBreadcrumb={onNavigateBreadcrumb}
                                className='breadcrumb-reporting-page'
                            />
                        </div>
                    )}

                    {typeScreen === "web"
                        ? (
                            <div className="subheader" style={{ display: 'flex', alignItems: 'center' }}>
                                <p className='top-left-text'>{routeProperties.pageName}</p>
                            </div>)
                        : (
                            <Subheader
                                year={year}
                                academicYears={academicYears}
                                onNavigate={onNavigate}
                                onSearch={onSearch}
                                schoolDistricts={schoolDistricts}
                                setYear={setYear}
                                inputValue={inputValue}
                                routeProperties={routeProperties}
                            />
                        )
                    }

                    {Children.map(children, (child) => {
                        return cloneElement(child, {
                            children: Children.map(child.props.children, (child_2) => {
                                return cloneElement(child_2, {
                                    element: React.isValidElement(child_2.props.element) ? cloneElement(child_2.props.element, { siteMenu, selectedYear: getSiteSetting(SiteSetting.AcademicYear) }) : child_2.props.element
                                })
                            })
                        })
                    })}
                </div >
                <SiteFooter />
            </div>
        )
    }

    return (pathname === "/" || pathname === "/home") ? renderHomePage() : renderAuxiliaryPages()
}

export default Body;