import React, { useEffect, useState } from 'react';
import OEButton, { ButtonStyle } from '../../core/components/form/OEButton';
import { OEFormLabel } from '../../core/components/form/OEForm';
import OERadioBoolean from '../../core/components/form/OERadioBoolean';
import OEHeading from '../../core/components/general/OEHeading';
import OEModal, { ModalSize, OEModalBody } from '../../core/components/general/OEModal';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { INotification } from '../../core/components/messaging/entities/Notification';
import { ColumnType, IColumn } from '../../core/components/table/entities/Column';
import OETable from '../../core/components/table/OETable';
import { Icon } from '../../core/entities/Icon';
import { processPostRequest } from '../../core/services/PostRequest';
import { formatInteger } from '../../core/utilities/String';
import { GlossaryImportAction, IGlossaryImportResults } from '../entities/GlossaryImportResults';
import { useImportGlossary } from '../services/GlossaryService';


interface IFormikInfo {
    onDone: () => void;
    setNotification: (i: INotification) => void;
}

const GlossaryImport: React.FunctionComponent<IFormikInfo> = ({ setNotification, onDone }) => {

    const { service: importService, setGlossaryImport } = useImportGlossary();
    const [file, setFile] = useState<File | null>(null);
    const [results, setResults] = useState<IGlossaryImportResults>();
    const [updateOnly, setUpdateOnly] = useState<boolean>(false);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: importService,
            processResults: processResults,
            successMessage: 'Glossary Imported Successfully',
            errorMessage: 'Error occurred on Importing Glossary',
        });
        // eslint-disable-next-line
    }, [importService])

    const processResults = (i: any) => {
        setResults(i.data);
    }

    const readFileDataAsBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                resolve(event.target.result);
                setGlossaryImport({
                    importAction: updateOnly ? GlossaryImportAction.Update : GlossaryImportAction.Overwrite,
                    webFileInfo: {
                        name: file.name,
                        contentType: file.type,
                        data: event.target.result.split("base64,")[1]
                    }
                })
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    const onChangeFile = (event: any) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    }

    const onImport = () => {
        file && readFileDataAsBase64(file);
    }

    const columns: IColumn[] = [
        { id: 'lineNumber', name: 'Line #', sort: true, type: ColumnType.Integer },
        { id: 'message', name: 'Error', sort: true, type: ColumnType.String, },
    ];

    return (
        <OEModal oeSize={ModalSize.Medium} show={true} onHide={onDone} title="Import Glossary">
            <OEModalBody>
                <OERow>
                    <OECol sm={4}>
                        <OEFormLabel htmlFor="action">Type of Import</OEFormLabel>
                    </OECol>

                    <OECol sm={8}>
                        <OERadioBoolean
                            noText ="Overwrite/Replace All"
                            yesText="Append Only"
                            name="action"
                            value={updateOnly}
                            onChange={setUpdateOnly}
                        />
                    </OECol>
                </OERow>
                <OERow>
                    <OECol sm={10}>
                        <input className="m-t-10 m-b-10 pull-left" type="file" accept='.csv' onChange={onChangeFile} />
                    </OECol>
                    <OECol sm={2}>
                        <OEButton
                            onClick={onImport}
                            hidden={file === null}
                            text="Upload"
                            icon={Icon.Upload}
                        />
                    </OECol>
                </OERow>
                {results && (
                    <>
                        <OEHeading text="Results" size={4} />
                        <p>
                            {`${formatInteger(results.recordsAdded)} were successfully added/updated, ${formatInteger(results.errors.length)} errors occurred`}
                        </p>
                        <OEHeading text="Errors" size={4} hidden={results.errors.length === 0} />
                        <OETable
                            loadingMessage="Loading Messages"
                            data={results.errors}
                            columns={columns}
                            hideTable={results.errors.length === 0}
                            showPagination={(results.errors.length || 0) > 10}
                            defaultSort="name"
                            defaultPageSize={10}
                        />
                    </>
                )}
                <OERow className="m-b-10">
                    <OECol sm={12}  >
                        <OEButton
                            className="pull-right"
                            bStyle={ButtonStyle.Cancel}
                            onClick={onDone}
                            text="Close"
                            icon={Icon.Close}
                        />
                    </OECol>
                </OERow>
            </OEModalBody>
        </OEModal >
    );
};

export default GlossaryImport;
