import React from 'react';
import AlertSubscriptionsList from '../../alert-subsciptions/components/AlertSubscriptionsList';
import ArtifactList from '../../artifacts/components/ArtifactList';
import { parseQueryString } from '../../core/utilities/URL';
import { DatasetConnectionList } from '../../dataset-connections/components/DatasetConnectionList';
import DcFileUploadList from '../../dc-file-upload/components/DcFileUploadList';
import DcFormsList from '../../dc-forms/components/DcFormsList';
import { DcFormsView } from '../../dc-forms/components/DcFormsView';
import { AnalystDictionary } from '../../dictionaries/components/AnalystDictionary';
import { ElementDictionary } from '../../dictionaries/components/ElementDictionary';
import Glossary from '../../glossary/components/Glossary';
import GlossaryTable from '../../glossary/components/GlossaryTable';
import OrganizationAdmin from '../../organization/OrganizationAdmin';
import MSEmbeddedPublic from '../../public/report-microstrategy/MSEmbeddedPublic';
import Reference from '../../reference/components/Reference';
import ReferenceTable from '../../reference/components/ReferenceTable';
import MSDataDownload from '../../report-microstrategy/components/templates/data-download/MSDataDownload';
import MSEmbeddedPage from '../../report-microstrategy/components/templates/report-only/MSEmbeddedPage';
import MSEmbeddedTierOne from '../../report-microstrategy/components/templates/single-tier/MSEmbeddedTierOne';
import MSEmbeddedTierThree from '../../report-microstrategy/components/templates/three-tier/MSEmbeddedTierThree';
import BIEmbeddedPage from '../../report-powerbi/components/BIEmbeddedPage';
import BIEmbeddedTierOne from '../../report-powerbi/components/BIEmbeddedTierOne';
import ReportAdmin from '../../reporting/components/admin-reports/ReportAdmin';
import ReportList from '../../reporting/components/report-list/ReportList';
import SiteReports from '../../reporting/components/site-reports/SiteReports';
import StyleGuide from '../../style-guide/StyleGuide';
import About from '../../user/About';
import UserAdmin from '../../user/UserAdmin';
import UserProfile from '../../user/UserProfile';
import Widget from '../../widgets/Widget';
import WidgetAdmin from '../../widgets/WidgetAdmin';
import { ICustomRouteProperties } from '../branding/entities/CustomRouteProperties';
import Dashboard from '../branding/pages/Dashboard';
import Maintenance from '../branding/pages/Maintenance';
import Oops from '../Oops';
import PageNotFound from '../PageNotFound';
import SiteAdmin from '../SiteAdmin';

export interface ICoreComponentInfo {
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void;
}

interface IComponentInfo extends ICoreComponentInfo {
    name: string;
}

export const OECoreComponent: React.FunctionComponent<IComponentInfo> = ({ name, routeProperties, setRouteProperties }) => {
    const params: any = parseQueryString();
    switch (name.toLowerCase()) {

        case 'about':
            return (<About routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'alertsubscriptionslist':
            return (<AlertSubscriptionsList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'analystdictionary':
            return (<AnalystDictionary routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'artifactlist':
            return (<ArtifactList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'biembeddedpage':
            return (<BIEmbeddedPage routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'biembeddedpagetierone':
            return (<BIEmbeddedTierOne routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'dashboard':
            return (<Dashboard routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'datasetconnectionlist':
            return (<DatasetConnectionList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'dcformslist':
            return (<DcFormsList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'dcformsview':
            return (<DcFormsView routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'dcfileuploadlist':
            return (<DcFileUploadList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'elementdictionary':
            return (<ElementDictionary routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'glossary':
            return (<Glossary routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'glossarytable':
            return (<GlossaryTable routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'maintenance':
            return (<Maintenance routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'msembeddedtierthree':
            return (<MSEmbeddedTierThree routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'msembeddedpage':
            return (<MSEmbeddedPage routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'msembeddedtierone':
            return (<MSEmbeddedTierOne routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'msembeddedpublic':
            return (<MSEmbeddedPublic routeProperties={routeProperties} setRouteProperties={setRouteProperties} pageId={params['pid'] || ''} />);

        case 'msdatadownload':
            return (<MSDataDownload routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'oops':
            return (<Oops routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'organizationadmin':
            return (<OrganizationAdmin routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'reference':
            return (<Reference routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'referencetable':
            return (<ReferenceTable routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'reportadmin':
            return (<ReportAdmin routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'reportlist':
            return (<ReportList routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'styleguide':
            return (<StyleGuide routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'siteadmin':
            return (<SiteAdmin routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'sitereports':
            return (<SiteReports routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'useradmin':
            return (<UserAdmin routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'userprofile':
            return (<UserProfile routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'widget':
            return (<Widget routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        case 'widgetadmin':
            return (<WidgetAdmin routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);

        default:
            return (<PageNotFound routeProperties={routeProperties} setRouteProperties={setRouteProperties} />);
    }
};