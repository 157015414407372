/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import OEButton from '../../../core/components/form/OEButton';
import OEForm, { OEFormGroup, OEFormLabel } from '../../../core/components/form/OEForm';
import OEInput, { OEInputType } from '../../../core/components/form/OEInput';
import OEHeading from '../../../core/components/general/OEHeading';
import OEWell, { WellStyle } from '../../../core/components/general/OEWell';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OENotification from '../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../core/components/messaging/entities/Notification';
import { emptyGUID } from '../../../core/utilities/String';
import { navigateToPage, parseQueryString } from '../../../core/utilities/URL';
import { IReport, canDebugReport, defaultReport } from '../../../reporting/entities/Report';
import { ReportConfigurationSuppression, ReportConfigurationType, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { ProcessingPage, ProcessingReport, getProcessingPageLabel, logProcessingPage } from '../../../reporting/entities/ReportProcessing';
import { useGetReport } from '../../../reporting/services/ReportService';
import Cube from '../../components/common/Cube';
import DossierFilters from '../../components/common/DossierFilters';
import PageFiltersOld from '../../components/common/PageFiltersOld';
import TokenValidation from '../../components/common/TokenValidation';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { IReportFilter } from '../../entities/ReportFilter';
import { ICubeAttribute } from '../../entities/api/CubeResponse';
import { IDossierDefinition, getDefaultDossierDefinition } from '../../entities/api/DossierDefinition';


const MicrostrategyCubeFilters: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const [reportId, setReportId] = useState<string>(params['id'] || 'fd66e5cb-2494-d09e-88e9-3a12b8ea2874');
    const { service: reportService, setItemId: setGetReportId } = useGetReport();

    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);

    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [reportProcessingStep, setReportProcessingStep] = useState<ProcessingReport>(ProcessingReport.Idle);
    const [debug] = useState<boolean>(canDebugReport);
    const [validToken, setValidToken] = useState<boolean>(false);
    const [report, setReport] = useState<IReport>(defaultReport);
    const [dossierDefinition, setDossierDefinition] = useState<IDossierDefinition>(getDefaultDossierDefinition());

    const [error, setError] = useState<any>();
    const [result, setResult] = useState<string>('');
    const [cubeId, setCubeId] = useState<string>('');
    const [tokenId, setTokenId] = useState<string>('');
    const [debugMessage, setDebugMessage] = useState<string>('');
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    /// Filter States
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);

    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);
    const [dossierFilters, setDossierFilters] = useState<IMicroStrategyFilter[]>([]);


    useEffect(() => {
        logProcessingPage(processingStep, debug);
        setDebugMessage(getProcessingPageLabel(processingStep));
        switch (processingStep) {
            case ProcessingPage.ReportLoad:
                setGetReportId(reportId);
                setDebugMessage('Loading Report');
                setProcessingStep(ProcessingPage.ReportLoading);
                break;

            case ProcessingPage.PageFiltersUpdated:
                setReportProcessingStep(ProcessingReport.DossierFiltersLoad);
                break;

            case ProcessingPage.DossierFiltersLoaded:
                setProcessingStep(ProcessingPage.Complete);
                break;

            case ProcessingPage.Complete:
                setDebugMessage('Processing Complete');
                break;

        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        switch (reportProcessingStep) {
            case ProcessingReport.DossierFiltersLoaded:
                setProcessingStep(ProcessingPage.DossierFiltersLoaded);
                setReportProcessingStep(ProcessingReport.Complete);
                break;
        }
        // eslint-disable-next-line
    }, [reportProcessingStep]);

    useEffect(() => {
        if (validToken) {
            setProcessingStep(ProcessingPage.Idle);
        }
        // eslint-disable-next-line
    }, [validToken]);


    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (!emptyGUID(report.id)) {
            setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (cubeAttributes.length > 0 && reportFilters.length > 0) {
            // setLoadingFilters([...reportFilters]);
        }
        // eslint-disable-next-line
    }, [cubeAttributes]);

    const onGetReport = () => {
        setCubeAttributes([]);
        setResult('');
        setError('');
        setProcessingStep(ProcessingPage.ReportLoad);
    };

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
    }

    const onCubeDefinition = () => {
        navigateToPage(`/debug-cubedefinition?id=${reportId}`);
    };


    return (
        <div className="container m-t-40">
            <TokenValidation validToken={validToken} setValidToken={setValidToken} />
            {!validToken && (
                <OEMessage hideDismissable={true} message="Token is not valid" />
            )}
            {validToken && (
                <>
                    <OENotification setNotification={setNotification} notification={notification} />
                    <OEHeading size={1} text="MicroStrategy Report Filters Testing" />
                    <OEForm>
                        <OERow>
                            <OECol sm={4}>
                                <OEFormGroup inline={true} className="m-r-10">
                                    <OEFormLabel>Report ID</OEFormLabel>
                                    <OEInput onChange={setReportId} type={OEInputType.Text} value={reportId} />
                                </OEFormGroup>
                            </OECol>
                            <OECol sm={4}>
                                <OEFormGroup inline={true} className="m-r-10">
                                    <OEFormLabel>Token ID</OEFormLabel>
                                    <OEInput disabled={true} type={OEInputType.Text} value={tokenId} />
                                </OEFormGroup>
                            </OECol>
                            <OECol sm={4}>
                                <OEFormGroup inline={true} className="m-r-10">
                                    <OEFormLabel>Cube ID</OEFormLabel>
                                    <OEInput disabled={true} type={OEInputType.Text} value={cubeId} />
                                </OEFormGroup>
                            </OECol>
                        </OERow>
                    </OEForm>
                    <OERow>
                        <OECol className="text-center" sm={2}>
                            <OEButton className="m-b-40 m-t-20" onClick={onGetReport} text="Load Cube" />
                        </OECol>
                        <OECol sm={8}>
                            <OEWell oeStyle={WellStyle.BorderedShaded} >
                                <span className="text-success">{`${debugMessage}`}</span>
                            </OEWell>
                        </OECol>
                        <OECol className="text-center" sm={2}>
                            <OEButton className="m-b-40 m-t-20" onClick={onCubeDefinition} text="Goto Definition" />
                        </OECol>
                    </OERow>

                    {report.title !== '' && (
                        <>
                            <h2>{report.title}</h2>
                            <Cube
                                validToken={validToken}
                                setCube={setCube}
                                report={report}
                                setNotification={setNotification}
                                cubeId={cubeId}
                                setResult={setResult}
                                setError={setError}
                                setDebugMessage={setDebugMessage}
                            />
                            <div className="content">
                                <OEWell title="Page Filters">
                                    <OERow>
                                        <OECol sm={8}>
                                            <PageFiltersOld
                                                setNotification={setNotification}
                                                pageProcessingStep={processingStep}
                                                report={report}
                                                setPageProcessingStep={setProcessingStep}
                                                setPageFilters={setPageFilters}
                                                cubeAttributes={cubeAttributes}
                                                setDebugMessage={setDebugMessage}
                                            />
                                        </OECol>
                                        <OECol sm={4}>
                                            {pageFilters.map((i, index) =>
                                                <div style={{ fontSize: '12px' }} key={index}>{i.label}: {i.selection}</div>
                                            )}
                                        </OECol>
                                    </OERow>
                                </OEWell>
                                <OEWell title="Dossier Filters">
                                    <OERow>
                                        <OECol sm={8}>
                                            <DossierFilters
                                                setNotification={setNotification}
                                                reportProcessingStep={reportProcessingStep}
                                                report={report}
                                                setReportProcessingStep={setReportProcessingStep}
                                                pageFilters={pageFilters}
                                                setDossierFilters={setDossierFilters}
                                                cubeAttributes={cubeAttributes}
                                                setDebugMessage={setDebugMessage}
                                                setPageDossierDefinition={setDossierDefinition}
                                                chapterIndex={0}
                                                suppression={ReportConfigurationSuppression.UserOff}
                                            />
                                        </OECol>
                                        <OECol sm={4}>
                                            {dossierFilters.map((i, index) =>
                                                <div style={{ fontSize: '12px' }} key={index}>{i.label}: {i.selection}</div>
                                            )}
                                        </OECol>
                                    </OERow>
                                </OEWell>
                            </div>
                            <OERow>
                                <OECol sm={6}>
                                    <h5>Response</h5>
                                    <OEInput type={OEInputType.LargeText} rows={2} value={result} disabled={true} />
                                </OECol>
                                <OECol sm={6}>
                                    <h5>Error</h5>
                                    <OEInput type={OEInputType.LargeText} rows={2} value={error} disabled={true} />
                                </OECol>
                            </OERow>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default MicrostrategyCubeFilters;
