import React, { Children, CSSProperties } from 'react';
import { Form, FormControl } from 'react-bootstrap';

/// -----------------------------------------------
/// Form Control Component
/// -----------------------------------------------
interface IOEFormControlInfo {
    className?: string;
    value?: any;
    onChange?: (event: any) => void;
    onKeyPress?: (event: any) => void;
    placeholder?: string;
    disabled?: boolean;
    checked?: boolean;
    id?: string;
    autoFocus?: boolean;
    style?: CSSProperties;
    maxLength?: number;
    autoComplete?: string;
    min?: number;
    max?: number;
    type?: string;
    ref?: any;
    children?: any;
    onClick?: () => void;
    accept?: string;
}

export const OEFormControl: React.FunctionComponent<IOEFormControlInfo> = ({
    value, onChange, onKeyPress, id, className, placeholder, disabled, style, children,
    autoFocus, maxLength, autoComplete, min, max, type, checked, ref, onClick, accept

}: IOEFormControlInfo) => {

    return (
        <FormControl
            id={id}
            value={value}
            onChange={onChange}
            className={`${className || ''}  m-r-5`}
            placeholder={placeholder}
            disabled={disabled}
            style={style}
            onKeyPress={onKeyPress}
            autoFocus={autoFocus}
            maxLength={maxLength}
            checked={checked}
            autoComplete={autoComplete}
            min={min}
            max={max}
            type={type}
            ref={ref}
            onClick={onClick}
            accept={accept}
        >
            {Children.map(children, i => i)}
        </FormControl>
    );
};

/// -----------------------------------------------
/// Form Group Component
/// -----------------------------------------------
interface IOEFormGroupInfo {
    className?: string;
    children?: any;
    inline?: boolean;
}

export const OEFormGroup: React.FunctionComponent<IOEFormGroupInfo> = ({ className, children, inline }: IOEFormGroupInfo) => {

    return (
        <div className={`${inline ? 'col' : ''} ${className || ''} `}>
            {Children.map(children, i => i)}
        </div>
    );
};


/// -----------------------------------------------
/// Form Label Component
/// -----------------------------------------------
interface IOEFormLabelInfo {
    className?: string;
    htmlFor?: string;
    required?: boolean;
    children?: any;
}

export const OEFormLabel: React.FunctionComponent<IOEFormLabelInfo> = ({ htmlFor, className, children, required }: IOEFormLabelInfo) => {

    return (
        <label
            htmlFor={htmlFor}
            className={`${className || ''} m-r-5`}
        >
            {Children.map(children, i => i)}
            {required && (
                <span>*</span>
            )}
        </label>
    );
};


/// -----------------------------------------------
/// Form Component
/// -----------------------------------------------
interface IFormInfo {
    className?: string;
    htmlFor?: string;
    required?: boolean;
    children?: any;
}

const OEForm: React.FunctionComponent<IFormInfo> = ({ htmlFor, className, children, required }: IFormInfo) => {

    return (
        <Form
            className={className || ''}
        >
            {Children.map(children, i => i)}
        </Form>
    );
};

export default OEForm;