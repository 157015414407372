import React, { useEffect, useState } from 'react';
import OEFilter from '../../core/components/filter/OEFilter';
import { IFilter } from '../../core/components/filter/entities/Filter';
import OEHeading from '../../core/components/general/OEHeading';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../core/components/messaging/entities/Notification';
import OETable from '../../core/components/table/OETable';
import { ColumnType, IColumn, ModifiedColumns } from '../../core/components/table/entities/Column';
import { IColumnAction } from '../../core/components/table/entities/ColumnAction';
import { Icon } from '../../core/entities/Icon';
import { defaultPaginationFilter, IPaginationFilter } from '../../core/entities/PaginationFilter';
import { processPostRequest } from '../../core/services/PostRequest';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { defaultGlossary, IGlossary } from '../entities/Glossary';
import { getGlossaryList, useDeleteGlossary, useGetGlossaries } from '../services/GlossaryService';
import GlossaryFormik from './GlossaryFormik';
import GlossaryImport from './GlossaryImport';

enum ModalTypes {
    None = 1,
    Edit,
    Permissions,
    Import
}

const GlossaryTable: React.FunctionComponent<ICoreComponentInfo> = () => {

    const { service, setParams } = useGetGlossaries();
    const { service: deleteService, setDeleteId } = useDeleteGlossary();

    const [items, setItems] = useState<IGlossary[]>([]);
    const [filterList, setFilterList] = useState<IGlossary[]>([]);
    const [item, setItem] = useState<IGlossary>(defaultGlossary);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [paginationParams, setPaginationParams] = useState<IPaginationFilter>(defaultPaginationFilter);

    useEffect(() => {
        if (service && service.result && service.result.totalCount && service.result.totalCount > 0) {
            setItems(getGlossaryList(service.result));
        } else {
            setItems([]);
            setFilterList([]);
        }
    }, [service]);

    useEffect(() => {
        processPostRequest({
            setNotification,
            service: deleteService,
            successAction: refreshItems,
            successMessage: 'Glossary Deleted Successfully',
            errorMessage: 'Error occurred deleting Glossary',
        });
        // eslint-disable-next-line
    }, [deleteService]);

    const refreshItems = () => {
        setShowModal(ModalTypes.None);
        setParams({ ...paginationParams });
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSave = () => {
        setShowModal(ModalTypes.None);
        setParams({ ...paginationParams });
    };

    const onEdit = (i: IGlossary) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onImport = () => {
        setShowModal(ModalTypes.Import);
    };

    const onDelete = (i: number) => {
        setDeleteId(i);
        setParams({ ...paginationParams });
    };

    const onConfirmDelete = (i: IGlossary) => {
        setConfirmation({
            setConfirmation,
            item: i.id,
            show: true,
            title: 'Delete Glossary Item?',
            message: `Are you sure you want to delete the glossary item ${i.term}?`,
            onOk: onDelete,
            onCancel: onCancel,
        });
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "childCount", notCondition: true, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'term', idNewLine: 'group', name: 'Term', sort: true, type: ColumnType.Link },
        { id: 'definition', name: 'Definition', sort: true, type: ColumnType.String, maxLength: 1000 },
        ...ModifiedColumns,
    ];

    const onAdd = () => {
        setItem(defaultGlossary);
        setShowModal(ModalTypes.Edit);
    };

    const filter: IFilter = {
        name: 'glossaryAdmin',
        autoSearch: true,
        filters: [
            { name: 'keyword', columns: ['group', 'term'], autoSearchLength: 3, autoSearch: true, label: 'Keyword', width: 500, placeholder: 'Search by Group, Term' },
        ],
    };

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Glossary Administration" icon={Icon.UserManagement} />
            <OENotification setNotification={setNotification} notification={notification} />
            <OEFilter
                singleLine={true}
                refresh={() => setParams({ ...paginationParams })}
                onFilter={(value) => {
                    setPaginationParams({ ...paginationParams, filter: value });
                    setParams({ ...paginationParams, filter: value });
                }}
                className="m-t-0"
                items={items}
                filter={filter}
                setFilterList={setFilterList}
                actionList={[
                    { icon: Icon.Add, text: 'Import CSV', action: onImport }
                ]}
            />
            <OETable
                data={filterList}
                columns={columns}
                showPagination={filterList.length > 10}
                showExport={true}
                loading={service.isInProgress}
                loadingMessage="Loading Glossary"
                defaultSort="textCol"
                defaultPageSize={25}
                noDataMessage={`No glossary exists.`}
                includeRowNumber={true}
                actions={[
                    { icon: Icon.Add, text: 'Add New Term', action: onAdd }
                ]}
            />

            {showModal === ModalTypes.Edit && (
                <GlossaryFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}

            {showModal === ModalTypes.Import && (
                <GlossaryImport onDone={onSave} setNotification={setNotification} />
            )}

            <OEConfirmation {...confirmation} />
        </div>
    );
};

export default GlossaryTable;