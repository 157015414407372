import React from 'react';
import { ICarousalItem, ISchoolList } from '../../../core/components/carousal/entities/Carousal';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';

const OrganizationItem: React.FunctionComponent<ISchoolList> = ({ titleIndex, selectedItem, items, navigateToSchoolReports }) => {

    const typeScreen = useResponsivenessContext()
    const filteredNames = items.filter((school: ICarousalItem) => school?.title?.startsWith(selectedItem)) // Filter names based on first character

    const chunkArray = (arr: ICarousalItem[], chunkSize: number) => {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            result.push(arr.slice(i, i + chunkSize));
        }
        return result;
    };

    const amountColumns = typeScreen === "web" ? 3 : typeScreen === "tablet" ? 2 : 1;
    const chunkedItems = chunkArray(filteredNames, filteredNames.length / amountColumns);
    const widthColumn = Math.floor(100 / amountColumns);

    return (
        // Carousal Changes
        // <div><p style={{ textAlign: 'center' }}>{titleIndex > 1 && SCHOOL_TITLE[selectedItem].title.substring(titleIndex).length > 1 ? SCHOOL_TITLE[selectedItem].title.substring(titleIndex) : SCHOOL_TITLE[selectedItem].title.substring(titleIndex, titleIndex + 1)}</p>
        //     <ul>
        //         {items.filter((school: ICarousalItem, index: number) => {
        //             return school.title && school.title.toUpperCase().startsWith(SCHOOL_TITLE[selectedItem].title.substring(titleIndex, titleIndex + 1))
        //         }).map((filterschool: ICarousalItem) => {
        //             return <li className='p-10 m-b-10' onClick={() => navigateToSchoolReports(filterschool)} key={filterschool.value}>{filterschool.title}</li>
        //         })}
        //         {titleIndex > 1 && SCHOOL_TITLE[selectedItem].title.substring(titleIndex).length > 1 && items.filter((school: ICarousalItem, index: number) => {
        //             return school.title && school.title.toUpperCase().startsWith(SCHOOL_TITLE[selectedItem].title.substring(titleIndex + 1, titleIndex + 2))
        //         }).map((filterschool: ICarousalItem) => {
        //             return <li className='p-10 m-b-10' onClick={() => navigateToSchoolReports(filterschool)} key={filterschool.value}>{filterschool.title}</li>
        //         })}
        //     </ul>
        // </div>
        <div style={styles.container}>
            {filteredNames.length > 0 ? chunkedItems.map((column: ICarousalItem[], index: number) => (
                <div key={index} style={{ ...styles.column, width: widthColumn + "%" }}>
                    {column.map((item, idx) => (
                        <div key={idx} tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') navigateToSchoolReports(item)
                            }} onClick={() => navigateToSchoolReports(item)}
                            style={{ ...styles.item }}>
                            <span key={idx} style={styles.SpanItem}>
                                {item.title}
                            </span>
                        </div>
                    ))}
                </div>
            )) : <span>No names found.</span>}
        </div>
    )
}

export default OrganizationItem;

const styles = {
    container: {
        display: 'flex',
        width: '100%'
    } as React.CSSProperties,
    column: {
        flexDirection: 'column',
    } as React.CSSProperties,
    item: {
        margin: '0',
        padding: 10,
        fontSize: '14px',
        height: 40,
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    } as React.CSSProperties,
    SpanItem: {
        margin: '0',
        padding: 10,
        fontSize: '14px',
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline'
    } as React.CSSProperties,
};
