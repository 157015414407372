import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IDropdownValues } from '../../../core/components/form/OEDropdown';
import Menu from '../../../core/components/menu/Menu';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { defaultHeaderText, IHeaderText } from '../../entities/HeaderText';
import { IRoute } from '../../entities/Route';
import Body from '../components/Body';
import Header from '../components/Header';
import SiteFooter from '../components/SiteFooter';
import { appRootBreadcrumbs, ICustomRouteProperties } from '../entities/CustomRouteProperties';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';
import { ISchoolOptionsSelection } from '../../../reporting/branding/entities/SchoolOptionsSelection';
import { saveSiteSetting, SiteSetting } from '../../entities/SiteSettings';

interface IComponentInfo {
    children?: any;
    toggleCollapse: () => void;
    menuCollapsed: boolean;
    siteMenu: MenuEntity[];
    isLoadingMenu: boolean;
    routes: IRoute[];
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void;
    width: number;
    schoolDistricts: ISchoolOptionsSelection[];
    onSearchSchoolDistricts: (val: string) => void;
    academicYears: IDropdownValues[];
    closeMenu: () => void;
}

const DefaultLayout: React.FunctionComponent<IComponentInfo> = ({
    children, isLoadingMenu, siteMenu, toggleCollapse, menuCollapsed,
    routes, routeProperties, setRouteProperties, width,
    schoolDistricts, onSearchSchoolDistricts, academicYears,
    closeMenu
}) => {

    const { pathname } = useLocation();
    const [pageName, setPageName] = useState('')
    const [headerText, setHeaderText] = useState<IHeaderText>(defaultHeaderText)
    const [activeLabels, setActiveLabels] = useState<string[]>([]);
    const typeScreen = useResponsivenessContext();
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (activeLabels) {
            localStorage.setItem('activeLabels', JSON.stringify(activeLabels));
        }
    }, [activeLabels])


    useEffect(() => {
        const active = localStorage.getItem('activeLabels');
        saveSiteSetting(SiteSetting.MSGroupId, '');
        saveSiteSetting(SiteSetting.MSSectionId, '');
        if (active) {
            setActiveLabels(JSON.parse(active));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (activeLabels) {
            localStorage.setItem('activeLabels', JSON.stringify(activeLabels));
        }
    }, [activeLabels])

    useEffect(() => {
        const active = localStorage.getItem('activeLabels');
        if (active) { setActiveLabels(JSON.parse(active)); }
        const page = routes.filter(rt => rt.path === pathname);
        setPageName(page[0]?.name ?? "")
        if (pathname === '/home' || pathname === '/') {
            setHeaderText({
                ...defaultHeaderText,
                middle: 'Welcome to the Kentucky School Report Card',
                left: 'Report Card'
            });
        } else {
            setHeaderText({
                ...defaultHeaderText,
                middle: 'Kentucky',
                left: 'Report Card'
            });
        }
        // eslint-disable-next-line
    }, [pathname, routes])

    useEffect(() => {
        routeProperties.setPageBreadcrumbs?.(pageName, [...appRootBreadcrumbs, { id: 1, name: pageName }]);
        document.title = `Kentucky Department of Education - ${pageName}`;
        // eslint-disable-next-line
    }, [pageName])

    const getHeight = () => {

        if (pathname === "/" || pathname === "/home") {
            if (typeScreen !== "web") {
                return 'auto'
            }

            return 'calc(100% - 110px)'
        }
        return 'calc(100% - 80px)'
    }

    const renderMenu = () => {
        const order = typeScreen === "web" ? true : menuCollapsed
        if (pathname !== '/' && pathname !== '/home') {
            return (
                <div className='three-tier' ref={wrapperRef}
                    style={{
                        width: order ? 290 : 0,
                        position: typeScreen !== "web" ? 'absolute' : 'relative',
                    }}>
                    <div className="left-nav"
                        style={{
                            backgroundColor: typeScreen !== "web" ? '#ffffff' : '#f7f7f7',
                            borderRight: typeScreen !== "web" ? '1px solid #BEBEBE' : ''
                        }}>
                        {typeScreen === "web" && (
                            <div style={{ padding: '10px 20px 10px 20px' }}>
                                <div className='separator' />
                            </div>)}
                        <Menu
                            closeMenu={closeMenu}
                            width={width}
                            items={siteMenu}
                            collapsed={menuCollapsed}
                            isLoading={isLoadingMenu}
                            activeLabels={activeLabels}
                            setActiveLabels={setActiveLabels}
                        />
                    </div>
                </div>
            )
        }

        return null

    }

    return (
        <>
            <Header
                collapsed={menuCollapsed}
                toggleCollapse={toggleCollapse}
                headerText={headerText}
                width={width}
                isCenter={false}
                pathname={pathname}
                routeProperties={routeProperties}
                setRouteProperties={setRouteProperties}
                layout={2}
                schoolDistricts={schoolDistricts}
                onSearchSchoolDistricts={onSearchSchoolDistricts}
                academicYears={academicYears}
            />

            <div className='layout-definition report-embedded m-b-0'
                style={{
                    height: getHeight(),
                    overflow: width < 1000 ? 'overlay' : 'unset'
                }} >
                {renderMenu()}
                <Body
                    children={children}
                    collapsed={menuCollapsed}
                    siteMenu={siteMenu}
                    pathname={pathname}
                    headerText={headerText}
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                    width={width}
                    schoolDistricts={schoolDistricts}
                    onSearchSchoolDistricts={onSearchSchoolDistricts}
                    academicYears={academicYears}
                    closeMenu={closeMenu}
                />
            </div >
            {(pathname === "/" || pathname === "/home") && <SiteFooter />}
        </>
    );
};

export default DefaultLayout;