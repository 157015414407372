
export interface IGlossaryImportResult {
    lineNumber: number;
    message: string;
}

export interface IGlossaryImportResults {
    recordsAdded: number;
    errors: IGlossaryImportResult[];
}

export enum GlossaryImportAction {
    Update = 1,
    Overwrite = 2,
}
