import React from 'react';
import OEIcon from '../../../../core/components/general/OEIcon';
import { Icon } from '../../../../core/entities/Icon';
import { getReportGroupIcon, IReportGroup } from '../../../../reporting/entities/ReportGroup';
import { ReportIconBaseURL } from '../../../../reporting/entities/ReportIcons';
import ReportDownload from './ReportDownload';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    group: IReportGroup;
    pageConfigruation: string;
    openSection: string[];
    setOpenSection: (id: string) => void;
    level: number;
    parentGroups: number;
    year: string;
}

const GroupList: React.FunctionComponent<IComponentInfo> = ({ group, year, pageConfigruation, openSection, setOpenSection, level, parentGroups }) => {

    const toggleSection = (id: string) => {
        setOpenSection(id);
    };

    const sectionOpen = (id?: string): boolean => {
        return (openSection.length > 0 && id && openSection.includes(id)) || false;
    };

    const hasImage = (): boolean => {
        return (group.configuration && group.configuration.includes(".svg") && getReportGroupIcon(group.configuration).length && getReportGroupIcon(group.configuration) !== ReportIconBaseURL) || false;
    }

    return (
        <>
            {group.reports.length === 0 && (
                <>
                    <div>
                        {hasImage() && level === 0 && (
                            <ul className='form-label m-t-20 no-p d-flex align-items-center'>
                                <img
                                    alt={`${group.title} icon`}
                                    width={50}
                                    className={`report-icon m-r-5`}
                                    src={getReportGroupIcon(group.configuration)}
                                />
                                <h3 className='title-glossary' aria-label={group.title}>{group.title} </h3>
                            </ul>
                        )}
                        {group.groups.length > 0 && (
                            <ul className='form-label m-t-15 no-p' key={group.title}>
                                {level > 0 && (
                                    <div tabIndex={0} onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') toggleSection(group.id)
                                    }} className='ml-20 d-flex align-items-center' style={{ width: "fit-content" }} onClick={() => toggleSection(group.id)}>
                                        {sectionOpen(group.id) && <OEIcon icon={Icon.Minus} tabIndex={-1} />}
                                        {!sectionOpen(group.id) && <OEIcon icon={Icon.Add} tabIndex={-1} />}
                                        <h4 className='description-glossary'> {group.title} </h4>
                                    </div>
                                )}
                                {group.groups.map((sl, index3) =>
                                    <GroupList
                                        level={level + 1}
                                        pageConfigruation={pageConfigruation}
                                        key={`${sl.id}${group.id}${index3}`}
                                        group={sl}
                                        openSection={openSection}
                                        setOpenSection={setOpenSection}
                                        parentGroups={group.groups.length}
                                        year={year}
                                    />
                                )}
                            </ul>
                        )}
                    </div>
                </>
            )}
            {group.reports.length > 0 && sectionOpen(group.reportGroupId) && (
                <>
                    <ul className='form-label m-t-10 ml-40 no-p'>
                        {parentGroups > 1 && (
                            <>
                                <h4 className='h6 align-self-center download-icon'> {group.title} </h4>
                                {group.reports.map((rl: any, index) =>
                                    <ul className='form-label' key={index}>
                                        <ReportDownload
                                            year={year}
                                            pageConfigruation={pageConfigruation}

                                            report={rl}
                                        />
                                    </ul>
                                )}
                            </>
                        )}
                        {parentGroups === 1 && group.reports.map((rl: any, index2) =>
                            <ReportDownload
                                pageConfigruation={pageConfigruation}
                                key={index2}
                                report={rl}
                                year={year}
                            />
                        )}
                    </ul>
                </>
            )}
        </>
    );
};

export default GroupList;
