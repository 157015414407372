import React, { useEffect, useRef, useState } from 'react';
import OEAutoComplete, { IAutoCompleteOptionSelections } from '../../core/components/form/OEAutoComplete';
import OEIcon from '../../core/components/general/OEIcon';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { Icon } from '../../core/entities/Icon';
import { useOutsideAlerter } from '../../core/hooks/useOnClickOutside';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { IGlossaryGroup } from '../entities/Glossary';
import { getGlossaryGroupList, useGetGlossaryTermGroup } from '../services/GlossaryService';


interface IGlossaryImportInfo {
    letter: IGlossaryGroup;
    onSelectLetter: (l: string) => void;
}

const GlossaryItem: React.FunctionComponent<IGlossaryImportInfo> = ({ letter, onSelectLetter }) => {
    return (
        <span translate='no'>
            <button className='glossary-link' onClick={() => onSelectLetter(letter.name)}>
                {letter.name}
            </button>
        </span>
    );
};

const Glossary: React.FunctionComponent<ICoreComponentInfo> = () => {

    const { service } = useGetGlossaryTermGroup();

    const elementRef = useRef<any>();

    const [isVisible, setIsVisible] = useState(false);
    const [items, setItems] = useState<IGlossaryGroup[]>([]);
    const [search, setSearch] = useState("")
    const [searchList, setSearchList] = useState<IAutoCompleteOptionSelections[]>([])
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, () => {
        setSearchList([])
    });

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.y >= 0
                );
                setIsVisible(!isVisible);
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (service.result) {
            setItems(getGlossaryGroupList(service.result));
        }
        // eslint-disable-next-line
    }, [service]);

    const scrollToLetter = (letter: string) => {
        const element = document.getElementById(letter);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            const glossaryItem = element.querySelector('.glossary-item') || element;

            setTimeout(() => {
                (glossaryItem as HTMLElement).focus();
            }, 500);

        }
    };

    const onSearch = (val: string) => {
        setSearch(val);
        if (val.length > 2) {
            let objArray: IAutoCompleteOptionSelections[] = [];
            items.forEach((glossary) => {
                for (const j of glossary.items.filter(q => q.definition.toUpperCase().includes(val.toUpperCase()) || q.term.toUpperCase().includes(val.toUpperCase()))) {
                    //objArray.push({ value: j.id, options: [renderItem(j.id, j.term, j.definition)] });
                    objArray.push({
                        value: j.id, label: (
                            <span key={j.id} style={{ fontSize: 14, fontWeight: 400, }}><b>{j.term}</b>
                                <br /><i>{j.definition}</i>
                            </span>
                        )
                    });
                }
            });
            setSearchList(objArray);
        } else {
            setSearchList([]);
        }
    }

    const onNavigate = (val: string) => {
        const element = document.getElementById(val);
        if (element) {
            const glossaryItem = element.querySelector('.glossary-item') || element;

            setTimeout(() => {
                (glossaryItem as HTMLElement).focus();
                (glossaryItem as HTMLElement).scrollBy({ top: -20, behavior: "smooth" });
            }, 500);

        }
    }

    return (
        <div ref={elementRef} id={"Glossary"} className="position-relative p30" style={{ height: 'calc(100% - 160px)' }}>
            <div className='box-container flex'>
                <div className="m-l-10" >
                    <div className="glossary-selectors">
                        {items.map((letter, index) => (
                            <GlossaryItem letter={letter} key={index} onSelectLetter={scrollToLetter} />
                        ))}
                    </div>
                </div>
                <div className='m-l-10'>
                    <OEAutoComplete
                        options={searchList}
                        style={{ width: '300px', borderRadius: 50, height: 40 }}
                        onNavigate={onNavigate}
                        popupMatchSelectWidth={500}
                        onSearch={onSearch}
                        placeholder="Search Glossary"
                        value={search}
                        ariaLabel="Search field for Glossary"
                    />

                </div>
            </div>

            <div
                id="glossary-content"
                role="region"
                aria-label="Glossary"
                style={{ overflowY: 'scroll', height: '100% ', overflowX: 'hidden' }}
            >
                {items.map((letter, index) => (
                    <OERow className="py-4" key={index} id={letter.name}>
                        <OECol style={{ flex: .1 }} className="">
                            <h2 translate='no' className='glossary-group' id={`heading-${letter.name}`} aria-label={`Letter-${letter.name}`} >{letter.name}</h2>
                        </OECol>
                        <OECol style={{ flex: 2 }} className=""> {letter.items.map((glossary, i) =>
                            <div key={i} className='glossary-item' tabIndex={0} id={glossary.id} aria-label={`Term: ${glossary.term}. Definition: ${glossary.definition}`}>
                                <h3 className='glossary-term' aria-label={`Glossary Term: ${glossary.term}`}>{glossary.term}</h3>
                                <p className='glossary-definition' aria-label={`Definition: ${glossary.definition}`}>{glossary.definition}</p>
                                <hr></hr>
                            </div>
                        )}
                        </OECol>
                    </OERow>
                ))}
            </div>
            {
                isVisible &&
                <div className='bottom-right'>
                    <OEIcon icon={Icon.CaretUp} onClick={() => scrollToLetter("Glossary")} />
                </div>
            }
        </div >
    );
};

export default Glossary;