import React, { useEffect, useState } from 'react';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import UserProfile from '../../../security/components/UserProfile';
import { emptyGUID } from '../../../core/utilities/String';
import { getSiteSetting, saveSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';
import { refreshPage } from '../../../core/utilities/URL';
declare global { var msLogout: any; }

interface IComponentInfo {
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void;
    reportingPage?: boolean;
    responsive?: boolean;
    className?: string;
}

const LoginUserProfile: React.FunctionComponent<IComponentInfo> = ({ routeProperties, reportingPage, responsive, className }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(getSiteSetting(SiteSetting.TranslateLanguage));

    useEffect(() => {
        setLoggedIn(!emptyGUID(routeProperties.user.id));
        // eslint-disable-next-line
    }, [routeProperties]);


    const saveTranslateStorage = (i: string) => {
        saveSiteSetting(SiteSetting.TranslateLanguage, i);
    }
    const translateLanguage = (lang: string) => {
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
        if (googleTranslateDropdown && !googleTranslateDropdown.value && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
    }

    const handleTranslateClick = (lang: string) => {
        if (lang !== language) {
            translateLanguage(lang);
            saveTranslateStorage(lang);
            if (emptyGUID(routeProperties.user.id)) {
                msLogout(getSiteSetting(SiteSetting.MSPath), getSiteSetting(SiteSetting.MSAuthenticationURL), processLogout);
                !reportingPage && setLanguage(lang);
            }
            else {
                setLanguage(lang);
            }
        }
    };

    const processLogout = () => {
        reportingPage && refreshPage();
    }


    return (
        <span className={className} >
            {!loggedIn && (
                <span
                    className='m-l-10 translate-container header-home-container'
                    onClick={() => handleTranslateClick(language === "en" ? 'es' : 'en')}
                    dangerouslySetInnerHTML={displayHTMLContent(language === "en" ? 'Espa&ntilde;ol' : 'Ingl�s')}
                />
            )}
            {loggedIn && (
                <UserProfile responsive={responsive} className="m-l-10" />
            )}
        </span>
    );
}

export default LoginUserProfile;