import React, { useContext, useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const ResponsivenessContext = React.createContext<string>("");

export const useResponsivenessContext = () => {
  const context = useContext(ResponsivenessContext);
  if (context === undefined) {
    throw new Error('useResponsivenessContext must be used within a ResponsivenessContextProvider');
  }
  return context;
};

export function ResponsivenessContextProvider(props: any) {

  const { width } = useWindowDimensions();
  const [type, setType] = useState<string>("");

  useEffect(() => {
    const getScreenType = () => {
      switch (true) {
        case (width <= 450):
          return 'mobile'
        case (width > 450 && width < 1000):
          return 'tablet'
        default:
          return 'web'
      }
    }
    setType(getScreenType());
  }, [width]);

  return (
    <ResponsivenessContext.Provider value={type}>
      {props.children}
    </ResponsivenessContext.Provider>
  );
}