/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IDropdownValuesSorted } from '../../../core/components/form/OEDropdown';
import OEMessage from '../../../core/components/messaging/OEMessage';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { IReport, canDebugReport, getReportCubeRequest } from '../../../reporting/entities/Report';
import { ReportConfigurationSuppression } from '../../../reporting/entities/ReportConfiguration';
import { ProcessingDossierFilters, ProcessingReport, getProcessingDossierFiltersLabel, logProcessingDossierFilters } from '../../../reporting/entities/ReportProcessing';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { isSuppressionFilter } from '../../entities/MicrostrategyDossierFilter';
import { IReportFilter, getReportFilterFromJSON } from '../../entities/ReportFilter';
import { CubeAttributeElementResponseSeparator, ICubeFilterAttribute, getCubeAttributeRawValueFromKey } from '../../entities/api/CubeAttributeElementResponse';
import { ICubeAttributeRequest, IFilterOperand, getDefaultCubeAttributeRequest } from '../../entities/api/CubeAttributeRequest';
import { ICubeAttributeResponse, defaultCubeAttributeResponse, getCubeAttributesfromAttributeResponse } from '../../entities/api/CubeAttributeResponse';
import { ICubeRequest } from '../../entities/api/CubeRequest';
import { ICubeAttribute } from '../../entities/api/CubeResponse';
import { IDossierDefinition, IDossierDefinitionFilter, getDefaultDossierDefinition } from '../../entities/api/DossierDefinition';
import { useGetDossierDefinition, usePostCubeAttributeElements } from '../../services/MicrostrategyService';
import MSFilterSelection from './MSFilterSelection';

interface IDossierFiltersInfo {
    report: IReport;
    pageFilters: IMicroStrategyFilter[];
    cubeAttributes: ICubeAttribute[];
    setNotification: (n: INotification) => void;
    reportProcessingStep: ProcessingReport;
    setReportProcessingStep: (i: ProcessingReport) => void;
    setDossierFilters: (i: IMicroStrategyFilter[]) => void;
    setDebugMessage?: (i: string) => void;
    setPageDossierDefinition: (i: IDossierDefinition) => void;
    chapterIndex: number;
    chapterList?: string;
    suppression: ReportConfigurationSuppression;
    hideFilters?: boolean;
}

const DossierFilters: React.FunctionComponent<IDossierFiltersInfo> = ({
    report, setNotification, setReportProcessingStep, setDossierFilters, suppression, setPageDossierDefinition,
    reportProcessingStep, cubeAttributes, setDebugMessage, pageFilters, chapterIndex, chapterList, hideFilters
}) => {

    const { service: cubePostAttributeService, setCube: setPostCubeAttributeElements } = usePostCubeAttributeElements();
    const { service: dossierGetDefinitionService, setCube: setDossierDefinitionRequest } = useGetDossierDefinition();

    const [localChapterList, setLocalChapterList] = useState<string>('');
    const [dossierDefinition, setDossierDefinition] = useState<IDossierDefinition>(getDefaultDossierDefinition());
    const [processingStep, setProcessingStep] = useState<ProcessingDossierFilters>(ProcessingDossierFilters.Idle);
    const [localFilters, setLocalFilters] = useState<IMicroStrategyFilter[]>([]);
    const [filters, setFilters] = useState<IMicroStrategyFilter[]>([]);
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);

    const [debug] = useState<boolean>(canDebugReport());
    const [error] = useState<string>('');
    const [filterLoadIndex, setFilterLoadIndex] = useState<number>(-1);
    const [cubeAttributeRequest, setCubeAttributeRequest] = useState<ICubeAttributeRequest>(getDefaultCubeAttributeRequest);
    const [cubeAttributeResponse, setCubeAttributeResponse] = useState<ICubeAttributeResponse>(defaultCubeAttributeResponse);
    const [filterAttributes, setFilterAttributes] = useState<ICubeFilterAttribute[]>([]);

    useEffect(() => {
        switch (reportProcessingStep) {
            case ProcessingReport.DossierFiltersLoad:
            case ProcessingReport.DossierFiltersInitialize:
                setProcessingStep(ProcessingDossierFilters.Start);
                break;

            case ProcessingReport.PageChanged:
                setProcessingStep(ProcessingDossierFilters.PageChanged);
                break;
        }
        // eslint-disable-next-line
    }, [reportProcessingStep]);

    useEffect(() => {
        chapterList && setLocalChapterList(chapterList);
        // eslint-disable-next-line
    }, [chapterList]);

    useEffect(() => {
        if (processingStep !== ProcessingDossierFilters.Idle) {
            setProcessingStep(ProcessingDossierFilters.CubeFilterStart);
        }
        // eslint-disable-next-line
    }, [chapterIndex]);

    useEffect(() => {
        logProcessingDossierFilters(processingStep, debug);
        setDebugMessage?.(getProcessingDossierFiltersLabel(processingStep));

        if (reportProcessingStep === ProcessingReport.FatalError) {
            setProcessingStep(ProcessingDossierFilters.FatalError);
            return;
        }
        switch (processingStep) {
            case ProcessingDossierFilters.Start:
                let f: IReportFilter[] = [];
                if (reportFilters.length > 0) {
                    f = reportFilters;
                }
                else {
                    f = getReportFilterFromJSON(report.filters);
                }
                for (const c of f) {
                    c.filterLoaded = false;
                }
                if (f.filter(q => q.dossier).length > 0) {
                    setReportFilters(f.filter(q => q.dossier));
                }
                else {
                    setProcessingStep(ProcessingDossierFilters.DossierDefinitionLoad);
                }
                break;

            case ProcessingDossierFilters.FiltersLoaded:
                initializeReportFilters();
                break;

            case ProcessingDossierFilters.DossierDefinitionLoad:
                setDossierDefinitionRequest({ ...getReportCubeRequest(report), run: true });
                break;

            case ProcessingDossierFilters.DosserDefinitionLoaded:
                setProcessingStep(ProcessingDossierFilters.CubeFilterStart);
                setPageDossierDefinition(dossierDefinition);
                break;

            case ProcessingDossierFilters.CubeFilterUpdateStart:
            case ProcessingDossierFilters.CubeFilterStart:
                if (reportFilters.length > 0) {
                    updateCubeRequest();
                }
                else {
                    setProcessingStep(ProcessingDossierFilters.Complete);
                }

                break;

            case ProcessingDossierFilters.CubeFilterUpdateLoadNext:
            case ProcessingDossierFilters.CubeFilterLoadNext:
                updateCubeRequestNext();
                break;

            case ProcessingDossierFilters.CubeFilterUpdateLoading:
            case ProcessingDossierFilters.CubeFilterLoading:
                const rf: IReportFilter = reportFilters[filterLoadIndex];
                let b = dossierDefinition.chapters[chapterIndex].filters.find(q => q.name === rf.name);
                if (!b || isSuppressionFilter(rf.name) || rf.cascadePriority.toString() === '99') {
                    setFilterLoadIndex(filterLoadIndex + 1);
                }
                else {

                    setDebugMessage?.(`Cube Filter Load - ${reportFilters[filterLoadIndex].name}`);
                    cubeAttributeRequest.requestedObjects.attributes = [];
                    cubeAttributeRequest.requestedObjects.attributes.push({ id: rf.msId });

                    const b: ICubeRequest = {
                        ...getReportCubeRequest(report),
                        id: cubeAttributes.filter(q => q.id === rf.msId)[0].cubeId,
                        body: cubeAttributeRequest,
                        run: true
                    };
                    setPostCubeAttributeElements(b);
                }
                break;

            case ProcessingDossierFilters.CubeFilterComplete:
                setFilterLoadIndex(-1);;
                setProcessingStep(ProcessingDossierFilters.UpdateFilters);
                break;

            case ProcessingDossierFilters.UpdateFilters:
                const d: IMicroStrategyFilter[] = [];
                for (const c of reportFilters) {
                    let s: string = '';
                    if (c.values) {
                        let f = c.values.filter(q => q.id === c.value);
                        if (f && f.length > 0) {
                            s = f[0].parentId;
                        }
                    }
                    d.push({
                        key: c.msId,
                        selection: c.mulitipleSelect ? c.value : s,
                        name: c.msName,
                        externalId: c.externalId,
                        pageFilter: true,
                        values: c.values,
                        value: c.value,
                        label: c.label,
                        cascadePriority: c.cascadePriority,
                        sortOrder: c.sortOrder,
                        displayValue: c.value,
                        supportMultiple: c.filterType === 'M',
                        rangeIncrements: c.rangeIncrements || "2",
                        isSuppression: isSuppressionFilter(c.msName),
                        filterType: c.filterType,
                        defaultSelection: c.defaultSelection || '',
                        msKey: c.msKey
                    });
                }
                setLocalFilters(d);
                setDossierFilters(d);
                setLocalChapterList('');
                setProcessingStep(ProcessingDossierFilters.PageChanged);
                break;

            case ProcessingDossierFilters.PageChanged:
                updateDossierFilters();
                break;

            case ProcessingDossierFilters.Complete:
                setReportProcessingStep(ProcessingReport.DossierFiltersLoaded);
                break;

            case ProcessingDossierFilters.FatalError:
                setDossierFilters([]);
                setReportProcessingStep(ProcessingReport.FatalError);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (!dossierGetDefinitionService.isInProgress && processingStep === ProcessingDossierFilters.DossierDefinitionLoad) {
            if (dossierGetDefinitionService.body) {
                setDossierDefinition(dossierGetDefinitionService.body);
            }
            if (dossierGetDefinitionService.error) {
                setNotification({ message: `${dossierGetDefinitionService.error}  (Report)`, type: 'error' })
            }
        }
        // eslint-disable-next-line
    }, [dossierGetDefinitionService]);

    useEffect(() => {
        if (processingStep === ProcessingDossierFilters.DossierDefinitionLoad) {
            setProcessingStep(ProcessingDossierFilters.DosserDefinitionLoaded);
        }
        // eslint-disable-next-line
    }, [dossierDefinition]);

    useEffect(() => {
        if (dossierDefinition.id !== '') {
            updateDossierFilters();
        }
        // eslint-disable-next-line
    }, [dossierDefinition]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingDossierFilters.Start:
                setProcessingStep(ProcessingDossierFilters.FiltersLoaded);
                break;
            case ProcessingDossierFilters.FiltersLoaded:
                setProcessingStep(ProcessingDossierFilters.DossierDefinitionLoad);
                break;
        }
        for (const f of reportFilters) {
            let g = localFilters.find(q => q.label === f.label);
            if (g) {
                if (JSON.stringify(g.values) !== JSON.stringify(f.values)) {
                    g.values = f.values;
                }
            }
        }
        updateDossierFilters();
        // eslint-disable-next-line
    }, [reportFilters]);

    useEffect(() => {
        if (filterLoadIndex >= 0) {
            setProcessingStep(ProcessingDossierFilters.CubeFilterLoadNext);
        }
        // eslint-disable-next-line
    }, [filterLoadIndex]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingDossierFilters.CubeFilterStart:
                setProcessingStep(ProcessingDossierFilters.CubeFilterLoadNext);
                break;
            case ProcessingDossierFilters.CubeFilterLoadNext:
                setProcessingStep(ProcessingDossierFilters.CubeFilterLoading);
                break;
            case ProcessingDossierFilters.CubeFilterUpdateStart:
                setProcessingStep(ProcessingDossierFilters.CubeFilterUpdateLoadNext);
                break;
            case ProcessingDossierFilters.CubeFilterUpdateLoadNext:
                setProcessingStep(ProcessingDossierFilters.CubeFilterUpdateLoading);
                break;
        }
        // eslint-disable-next-line
    }, [cubeAttributeRequest]);

    useEffect(() => {
        if (!cubePostAttributeService.isInProgress) {
            if (cubePostAttributeService.body) {
                setCubeAttributeResponse(cubePostAttributeService.body);
            }
            if (cubePostAttributeService.error) {
                setNotification({ message: `Getting Cube Attribute Elements (DossierFilters) ${reportFilters[filterLoadIndex].name} ${cubePostAttributeService.error}`, type: 'error' })
                setFilterLoadIndex(filterLoadIndex + 1);
            }
        }
        // eslint-disable-next-line
    }, [cubePostAttributeService]);

    useEffect(() => {
        try {
            if (cubeAttributeResponse.result) {
                setFilterAttributes(getCubeAttributesfromAttributeResponse(cubeAttributeResponse.result.data.root.children));
            }
        }
        catch (e: any) {
            setReportProcessingStep(ProcessingReport.NoDataFound);
            setProcessingStep(ProcessingDossierFilters.Idle);
            //setNotification({ message: `DossierFilters (cubeAttributeResponse) ${e.message}`, type: 'error' })
            console.log(cubeAttributeResponse.result);
            console.log(report);
            console.log(dossierDefinition);
            console.log(pageFilters);
            console.log(cubeAttributes);
        }
        // eslint-disable-next-line
    }, [cubeAttributeResponse]);

    useEffect(() => {
        if (filterAttributes.length > 0) {
            const rf: IReportFilter = reportFilters[filterLoadIndex];
            if (rf && rf.dossier) {
                rf.values = [];
                const sortedValues: IDropdownValuesSorted[] = [];
                let match = cubeAttributes.find(q => q.name === rf.name);
                if (match && match.elements) {
                    for (const r of filterAttributes) {
                        let value = match.elements.find(q => q.key === r.id);
                        if (value) {
                            let sort: number = -1;
                            try {
                                if (rf.rawValueSortIndex >= 0) {
                                    const s: string[] = value.rawValue.split(CubeAttributeElementResponseSeparator);
                                    sort = parseInt(s[rf.rawValueSortIndex], 10);
                                }
                            }
                            catch {
                                sort = -1;
                            }
                            sortedValues.push({ sort, id: r.id, name: value.value, parentId: r.id });
                        }
                    }
                    if (rf.rawValueSortIndex >= 0) {
                        sortedValues.sort(function (x, y) {
                            return x.sort - y.sort;
                        });
                    }
                    for (const v of sortedValues) {
                        rf.values.push({ id: v.id, name: v.name, parentId: v.parentId });
                    };
                }
                rf.value = getDefaultValue(rf);
                setReportFilters([...reportFilters]);
            }
            setFilterLoadIndex(filterLoadIndex + 1);
        }
        // eslint-disable-next-line
    }, [filterAttributes]);

    const getDefaultValue = (rf: IReportFilter): string => {
        let v: string = ''
        if (rf.value === '') {
            if (rf.mulitipleSelect) {
                for (const c of rf.values) {
                    v = `${v}${v.length > 0 ? ',' : ''}${c.id}`;
                }
            }
            else {
                v = rf.value = rf.values[0].id;
            }
        }
        else {
            // set value to all values in dropdown
            const f: string[] = rf.value.split(',');
            for (const s of f) {
                let matchValue = rf.values.find(q => q.id === s);
                if (matchValue) {
                    v = `${v}${v.length > 0 ? ',' : ''}${matchValue.id}`;
                }
            }
            /// if value not set get default value or set value to first item in dropdown
            if (v === '' && rf.values.length > 0) {
                if (rf.values.filter(q => q.name === rf.defaultValue).length > 0) {
                    v = rf.values.filter(q => q.name === rf.defaultValue)[0].id;
                }
                else {
                    v = rf.values[0].id;
                }
            }
        }
        return v;
    }

    const updateCubeRequest = () => {
        const c: ICubeAttributeRequest = { ...getDefaultCubeAttributeRequest() };
        if (pageFilters.length > 0) {
            const m: IMicroStrategyFilter = pageFilters[pageFilters.length - 1];
            let key: string = '';
            let selection: string = m.selection;
            for (const a of cubeAttributes) {
                for (const s of m.externalId.split(',')) {
                    if (s.trim().toLowerCase() === a.name.trim().toLowerCase()) {
                        key = a.id;
                        break;
                    }
                }
                if (key !== '') {
                    break;
                }
            }
            selection = selection.replace(m.key, key || m.key);
            if (selection !== '') {
                c.viewFilter.operands.push({ operator: 'In', operands: [] });
                c.viewFilter.operands[c.viewFilter.operands.length - 1].operands.push({ type: 'attribute', id: key });
                c.viewFilter.operands[c.viewFilter.operands.length - 1].operands.push({ type: 'elements', elements: [{ id: getCubeAttributeRawValueFromKey(selection) }] });
            }
        }
        setCubeAttributeRequest({ ...c });
        setFilterLoadIndex(0);
    }

    const updateCubeRequestNext = () => {
        if (filterLoadIndex < reportFilters.length) {
            const updating: boolean = processingStep === ProcessingDossierFilters.CubeFilterUpdateLoadNext;
            if (filterLoadIndex > 0) {
                const rf: IReportFilter = reportFilters[filterLoadIndex - 1];
                const ca: ICubeAttribute = cubeAttributes.filter(q => q.id === rf.msId)[0];
                const v: IFilterOperand[] = [];
                let ci: IDossierDefinitionFilter[] = dossierDefinition.chapters[chapterIndex].filters;
                try {
                    try {
                        if (localChapterList !== '') {
                            let b = dossierDefinition.chapters.find(q => q.name === localChapterList.split(';')[0]);
                            if (b) {
                                ci = b.filters;
                            }
                        }
                    }
                    catch {
                        console.log(dossierDefinition);
                        console.log(chapterIndex);
                        console.log(localChapterList);
                    }
                    if (ci.filter(q => q.source.id === rf.msId).length > 0) {
                        for (const f of rf.value.split(',')) {
                            if (ca.elements.filter(q => q.key === f).length > 0) {
                                v.push({ id: f });
                            }
                        }

                        cubeAttributeRequest.viewFilter.operands.push({ operator: 'In', operands: [] });
                        cubeAttributeRequest.viewFilter.operands[cubeAttributeRequest.viewFilter.operands.length - 1].operands.push({ type: 'attribute', id: rf.msId });
                        cubeAttributeRequest.viewFilter.operands[cubeAttributeRequest.viewFilter.operands.length - 1].operands.push({ type: 'elements', elements: v });
                    }
                }
                catch {
                    console.log('filter not found');
                }
                setCubeAttributeRequest({ ...cubeAttributeRequest });
            }
            else {
                setProcessingStep(updating ? ProcessingDossierFilters.CubeFilterUpdateLoading : ProcessingDossierFilters.CubeFilterLoading);
            }
        } else {
            setProcessingStep(ProcessingDossierFilters.CubeFilterComplete);
        }
    }

    const initializeReportFilters = () => {
        for (const r of reportFilters) {
            let d = cubeAttributes.find(q => q.name === r.name);
            if (!d) {
                for (const i of r.externalId.split(',')) {
                    d = cubeAttributes.find(q => q.name === i);
                }
            }
            if (d) {
                r.msId = d.id;
                r.values = [];
                for (const v of d.elements) {
                    r.values.push({ id: v.key, name: v.value, parentId: v.key });
                }

                if (r.values.length > 0) {
                    let match = r.values.find(q => q.name === r.defaultSelection);
                    if (match) {
                        r.value = match.id;
                    }
                    else {
                        r.value = r.values[0].id;
                    }
                }
            }
            if (isSuppressionFilter(r.name)) {
                r.value = (suppression === ReportConfigurationSuppression.AdminOn || suppression === ReportConfigurationSuppression.UserOn) ? "1" : "0";
            }

        }
        setReportFilters([...reportFilters]);
    }

    const updateFilterValues = (f: IMicroStrategyFilter) => {
        const t = reportFilters.find(q => q.msId === f.key);
        if (t) {
            t.value = f.value;
            if (f.supportMultiple) {
                t.valueName = 'Multiple Selections';
            }
            else if (!f.isSuppression) {
                t.valueName = f.values.filter(q => q.id === f.value)[0].name;
            }
            setProcessingStep(ProcessingDossierFilters.CubeFilterUpdateStart);
        }
    }

    const updateDossierFilters = () => {
        for (const f of localFilters) {
            let match = dossierDefinition.chapters[chapterIndex].filters.find(q => q.source.id === f.key);
            f.isSuppression = isSuppressionFilter(f.key);
            if (f.isSuppression) {
                f.hidden = suppression === ReportConfigurationSuppression.AdminOff || suppression === ReportConfigurationSuppression.AdminOn;
            }
            else {
                f.hidden = !match;
            }
        }
        setFilters(localFilters.filter(q => !q.hidden));
        setDossierFilters(localFilters.filter(q => !q.hidden || q.isSuppression));
        if (processingStep === ProcessingDossierFilters.PageChanged) {
            setProcessingStep(ProcessingDossierFilters.Complete);
        }
    }

    return (
        <>
            {!hideFilters && filters.length > 0 && (
                <MSFilterSelection
                    updateFilterValues={updateFilterValues}
                    filters={filters}
                    filterId={report.id}
                />
            )}
            {processingStep === ProcessingDossierFilters.FatalError && (
                <OEMessage
                    className="report-errors"
                    hideDismissable={true}
                    message={error}
                    type={MessageType.Error}
                />
            )}

        </>
    );
};

export default DossierFilters;
