import React, { useEffect, useState } from 'react';
import { IReportGroup, getReportGroupIcon, getReportGroupIconOff } from '../../../../reporting/entities/ReportGroup';
import { ReportIconBaseURL } from '../../../../reporting/entities/ReportIcons';
import OEIcon from '../../../../core/components/general/OEIcon';
import { Icon } from '../../../../core/entities/Icon';
import SectionItem from './SectionItem';
import { useResponsivenessContext } from '../../../../core/contexts/Resposiveness';


interface IComponentInfo {
    item: IReportGroup;
    collapsible: boolean;
    selected: boolean;
    onClick: (i: IReportGroup) => void;
    onChangeSection: (i: IReportGroup) => void;
    selectedSection: string;
    defaultSection: IReportGroup;
    clear: () => void;
    closeMenu?: () => void;
}

const SectionTitle: React.FunctionComponent<IComponentInfo> = ({
    item,
    onClick,
    selected,
    onChangeSection,
    collapsible,
    selectedSection,
    defaultSection,
    clear,
    closeMenu = () => { }

}) => {

    const [imgSrc, setImgSrc] = useState<string>('');
    const [imgOffSrc, setOffImgSrc] = useState<string>('');
    const [src, setSrc] = useState<string>('');
    const [className, setClassName] = useState<string>('');
    const typeScreen = useResponsivenessContext()

    useEffect(() => {
        setImgSrc(getReportGroupIcon(item?.configuration ?? ""));
        setOffImgSrc(getReportGroupIconOff(item?.configuration ?? ""));
        setSrc(selected ? imgSrc : imgOffSrc);
        // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
        setClassName(selected ? 'active' : '');
        setSrc(selected ? imgSrc : imgOffSrc);
        // eslint-disable-next-line
    }, [imgOffSrc, imgSrc, selected]);

    const onSelect = () => {
        collapsible && onClick(item);
    }

    const onMouseOver = () => {
        setClassName('active');
        setSrc(imgSrc);
    }

    const onMouseOut = () => {
        setClassName(selected ? 'active' : '');
        setSrc(selected ? imgSrc : imgOffSrc);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            onSelect();
        }
    };

    const handleSectionItemKeyDown = (event: React.KeyboardEvent, sectionItem: IReportGroup) => {
        if (event.key === 'Enter' || event.key === ' ') {
            onChangeSection(sectionItem);
        }
    };

    return (
        <>
            <div
                tabIndex={0}
                role="button"
                onKeyDown={handleKeyDown}
                aria-pressed={selected}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
                className={`section-header ${className} ${typeScreen !== "web" ? 'mobileMenu' : ''}`}
                onClick={selected ? clear : onSelect}
            >
                {item.icon && (
                    <i className={`m-r-5 ${item.icon}`} />
                )}
                {imgSrc !== ReportIconBaseURL && (
                    <img alt={item.title} className={`m-r-5`} src={src} ></img>
                )}
                <span >
                    {item.title}
                </span>

                {item?.groups?.filter(q => q.isActive && q.id !== defaultSection.id).length > 0 &&
                    <>
                        <OEIcon hidden={!selected} icon={Icon.CaretUp} className="pull-right" tabIndex={-1} />
                        <OEIcon hidden={selected} icon={Icon.CaretDown} className="pull-right" tabIndex={-1} />
                    </>
                }
            </div>

            {(!collapsible || selected) && (
                <>
                    {item?.groups?.filter(q => q.isActive && q.id !== defaultSection.id).map((item2, index2) =>
                        <div
                            key={index2}
                            tabIndex={0}
                            onKeyDown={(event) => handleSectionItemKeyDown(event, item2)}
                            role="button"
                            className={`section-item ${item2.id === selectedSection ? 'active' : ''}  ${typeScreen !== "web" ? 'mobileMenu' : ''}`}
                        >
                            <SectionItem onClick={(e) => {
                                onChangeSection(e)
                                closeMenu()
                            }} group={item2} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default SectionTitle;
