import React, { useEffect, useState } from 'react';
import Carousal from '../../../core/components/carousal/Carousal';
import { ICarousalItem } from '../../../core/components/carousal/entities/Carousal';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { navigateToPage } from '../../../core/utilities/URL';
import { useGetSchoolDistricts } from '../../../reporting/services/ReportFilterService';
import { ICoreComponentInfo } from '../../../site/components/OECoreComponent';
import OrganizationItem from './OrganizationItem';

interface IComponentInfo {
    typeId: string;
}

const OrganizationList: React.FunctionComponent<IComponentInfo & ICoreComponentInfo> = ({ routeProperties, typeId }) => {

    const [selectedItem, setSelectedItem] = useState<string>('A');

    const { service, setSearch } = useGetSchoolDistricts();
    const [items, setItems] = useState<ICarousalItem[]>([])

    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));


    const showCarousel = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) && window.innerWidth <= 550;

    const alphabetItems: ICarousalItem[] = alphabet.map(x => {
        return {
            title: x,
            value: x
        } as ICarousalItem
    })



    useEffect(() => {
        setSearch({ keyword: '%', academicYear: routeProperties.academicYearID, isSchool: true });
        // eslint-disable-next-line
    }, [routeProperties.academicYearID]);

    useEffect(() => {
        if (service.result) {
            const d: ICarousalItem[] = [];
            for (const v of service.result.filter(q => q.typeId === typeId)) {
                d.push({ value: v.id, title: v.name });
            }

            setItems(d);
        }
        // eslint-disable-next-line
    }, [service]);

    const navigateToSchoolReports = (item: ICarousalItem) => {
        navigateToPage(`/kysrc?organization=${item.value}`)
    }



    const handleLetterClick = (letter: string) => {
        setSelectedItem(letter)
    };

    const handleLetterClickOnNumber = (letterIndex: number) => {
        setSelectedItem(alphabet[letterIndex])
    };

    return (
        // Carousal Changes
        <>

            <div className="p-3 flex-grow-1 bg-white" style={styles.container}>
                {!showCarousel && <div className="container-options">
                    {alphabet.map((letter) => (
                        <span translate="no" className='glossary-link-container' key={letter}>
                            <button className={`glossary-link ${selectedItem === letter ? 'glossary-link-active' : ''}`} onClick={() => handleLetterClick(letter)} >
                                {letter}
                            </button>
                        </span>
                    ))}
                </div>}
                {showCarousel &&
                    <Carousal onClickItem={(i) => { }}
                        selectedItem={alphabetItems.findIndex(lt => lt.title === selectedItem)}
                        items={alphabetItems}
                        isDetail={true}
                        setSelectedItem={handleLetterClickOnNumber} />
                }

                <OESpinner message="Loading list" hidden={!service.isInProgress} />
                {!service.isInProgress && (
                    <div className="schoolContainer">
                        <OrganizationItem titleIndex={0} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
                    </div>
                )}
            </div>
        </>
    );
};

export default OrganizationList;

const styles = {
    container: {
        height: 'calc(100 % - 300px)',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
};
