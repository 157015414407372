import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { DropdownType, IDropdownValues, defaultDropdownIntegerSelect, defaultDropdownSelect } from '../../core/components/form/OEDropdown';
import { EditorHeight } from '../../core/components/form/OEEditor';
import OEFormBoolean from '../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../core/components/formik/OEFormDropdown';
import OEFormEditor from '../../core/components/formik/OEFormEditor';
import OEFormImage from '../../core/components/formik/OEFormImage';
import OEFormInput from '../../core/components/formik/OEFormInput';
import OEFormItem from '../../core/components/formik/OEFormItem';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import { FormInputType, IFormInfo } from '../../core/components/formik/entities/Form';
import OENotification from '../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../core/components/messaging/entities/Notification';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { emptyGUID } from '../../core/utilities/String';
import { useGetDatasetConnections } from '../../dataset-connections/services/DatasetConnectionService';
import ReportSelector from '../../reporting/components/common/ReportSelector';
import { IReport } from '../../reporting/entities/Report';
import { IArtifact } from '../entities/Artifact';
import { IArtifactDatasetViewColumns } from '../entities/ArtifactDatasetViewColumns';
import { ArtifactType } from '../entities/ArtifactType';
import { IDatasetCategory } from '../entities/DatasetCategory';
import { IDatasetFilter } from '../entities/DatasetFilter';
import artifactTypes from '../lookups/artifact-types.json';
import { useGetArtifactDatasetViewColumns, useGetArtifactDatasetViews } from '../services/ArtifactDatasetService';
import { useGetArtifactDomainTags, useGetArtifactSubjectTags } from '../services/ArtifactService';
import ArtifactTags from './ArtifiactTags';
import Categories from './Categories';
import Filters from './Filters';

const Form: React.FunctionComponent<FormikProps<IArtifact> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const { service: domainService } = useGetArtifactDomainTags();
    const { service: subjectService } = useGetArtifactSubjectTags();
    const { service: datasetService } = useGetDatasetConnections();
    const { service: datasetViewService, setDatasetConnectionId } = useGetArtifactDatasetViews();
    const { service: datasetViewColumnsService, setSearch } = useGetArtifactDatasetViewColumns();

    const [domainTags, setDomainTags] = useState<string[]>([]);
    const [subjectTags, setSubjectTags] = useState<string[]>([]);
    const [datasets, setDatasets] = useState<IDropdownValues[]>([]);
    const [views, setViews] = useState<IDropdownValues[]>([]);
    const [columnData, setColumnData] = useState<IArtifactDatasetViewColumns[]>([]);

    useEffect(() => {
        if (domainService.result) {
            setDomainTags(domainService.result.tags);
        }
        // eslint-disable-next-line
    }, [domainService]);

    useEffect(() => {
        if (values.dataset && values.dataset.databaseConnectionId) {
            setDatasetConnectionId(values.dataset.databaseConnectionId);
            if (values.dataset && values.dataset.datasetView && values.dataset.datasetView.length > 0) {
                setSearch({ datasetConnectionId: values.dataset.databaseConnectionId, view: values.dataset.datasetView });
            }
        }
        else {
            setViews([]);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (subjectService.result) {
            setSubjectTags(subjectService.result.tags);
        }
        // eslint-disable-next-line
    }, [subjectService]);

    useEffect(() => {
        if (datasetService.result) {
            const i: IDropdownValues[] = [];
            for (const c of datasetService.result.items) {
                i.push({ id: c.id, name: c.displayName });
            }
            setDatasets(i);
        }
        // eslint-disable-next-line
    }, [datasetService]);

    useEffect(() => {
        if (datasetViewService.result) {
            const i: IDropdownValues[] = [];
            for (const c of datasetViewService.result.items) {
                i.push({ id: c.id, name: c.datasetView });
            }
            setViews(i);
        }
        // eslint-disable-next-line
    }, [datasetViewService]);

    useEffect(() => {
        if (datasetViewColumnsService.result) {
            setColumnData(datasetViewColumnsService.result);
        }
        // eslint-disable-next-line
    }, [datasetViewColumnsService]);

    const setCategories = (i: IDatasetCategory[]) => {
        setFieldValue('dataset.categories', i);
    };

    const setFilters = (i: IDatasetFilter[]) => {
        setFieldValue('dataset.filters', i);
    };

    const onChangeReport = (i: IReport) => {
        setFieldValue('microStrategy.reportId', i.id);
        setFieldValue('microStrategy.reportName', i.title);
        setFieldValue('displayName', i.title);
        setFieldValue('description', i.description);
    };

    return (<>
        <OENotification setNotification={setNotification} notification={notification} />

        <OEFormDropdown label="Artifact Type" name="artifactType" value={values.artifactType} defaultSelect={defaultDropdownIntegerSelect}
            alwaysShowDefault={true} errors={errors} touched={touched} type={DropdownType.Integer} columns={labelColumns} values={artifactTypes}
            setFieldValue={setFieldValue} required={true} />
        {values.artifactType !== ArtifactType.AllArtifacts && (
            <>
                {values.artifactType === ArtifactType.MicroStrategy && (
                    <ReportSelector label="Report" name="microStrategy.reportId"
                        reportName={values.microStrategy.reportName} errors={errors} touched={touched} columns={labelColumns} onChange={onChangeReport} />
                )}

                <OEFormInput label="Display Name" name="displayName" value={values.displayName}
                    errors={errors} touched={touched} columns={labelColumns} required={true} setFieldValue={setFieldValue} inputType={FormInputType.String} />

                <OEFormInput label="Application" name="application" value={values.application}
                    errors={errors} touched={touched} columns={labelColumns} required={true} setFieldValue={setFieldValue} inputType={FormInputType.String} />

                <OEFormEditor label="Description" name="description" value={values.description}
                    errors={errors} touched={touched} columns={labelColumns} height={EditorHeight.Small}
                    required={true} setFieldValue={setFieldValue} />

                <OEFormItem columns={labelColumns} name="test" label="Domain Tags" errors={errors} touched={touched} required={true}>
                    <ArtifactTags value={values.domainTagsCsv} name="domainTagsCsv" setFieldValue={setFieldValue} values={domainTags} />
                </OEFormItem>

                <OEFormItem columns={labelColumns} name="test" label="Subject Tags" errors={errors} touched={touched} required={true}>
                    <ArtifactTags value={values.subjectTagsCsv} name="subjectTagsCsv" setFieldValue={setFieldValue} values={subjectTags} />
                </OEFormItem>

                <OEFormImage label="Thumbnail" labelInstructions="(Limit: 1MB)" errors={errors} touched={touched}
                    name="thumbnail" columns={labelColumns} style={{ maxHeight: '200px', maxWidth: '800px' }} accept="image/png, image/gif, image/jpeg"
                    setFieldValue={setFieldValue} maxSize={1} value={values.thumbnail}
                />

                <OEFormBoolean label="Published" name="isPublished" value={values.isPublished}
                    errors={errors} touched={touched} columns={labelColumns} setFieldValue={setFieldValue} />

                {+values.artifactType === ArtifactType.Dataset && (
                    <>
                        <OEFormDropdown label="Dataset Connection" name="dataset.databaseConnectionId"
                            value={values.dataset.databaseConnectionId} defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                            errors={errors} touched={touched} columns={labelColumns} values={datasets} setFieldValue={setFieldValue} required={true} />

                        {values.dataset && !emptyGUID(values.dataset.databaseConnectionId || '') && (
                            <>
                                <OEFormDropdown label="View" name="dataset.datasetView" value={values.dataset.datasetView} defaultSelect={defaultDropdownSelect}
                                    alwaysShowDefault={true} errors={errors} touched={touched} columns={labelColumns} values={views} setFieldValue={setFieldValue}
                                    required={true} />

                                {values.dataset && values.dataset.datasetView !== '' && (
                                    <>
                                        <Categories setNotification={setNotification} setCategories={setCategories}
                                            columnData={columnData} categories={values.dataset.categories} labelColumns={labelColumns} />
                                        <Filters setFilters={setFilters} filters={values.dataset.filters} labelColumns={labelColumns} columnData={columnData} />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                {+values.artifactType === ArtifactType.Url && (
                    <>
                        <OEFormInput label="Url" name="url.reportUrl" value={values.url.reportUrl} errors={errors} touched={touched}
                            columns={labelColumns} required={true} setFieldValue={setFieldValue} inputType={FormInputType.String} />
                    </>
                )}

                {+values.artifactType === ArtifactType.Ssrs && (
                    <>
                        <OEFormInput label="Report Path" name="ssrs.reportPath" value={values.ssrs.reportPath} errors={errors} touched={touched}
                            columns={labelColumns} required={true} setFieldValue={setFieldValue} inputType={FormInputType.String} />

                        <OEFormInput label="Report Name" name="ssrs.reportName" value={values.ssrs.reportName} errors={errors} touched={touched}
                            columns={labelColumns} required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}></OEFormInput>

                        <OEFormInput label="Report Query String" name="ssrs.reportQueryString" value={values.ssrs.reportQueryString} errors={errors}
                            touched={touched} columns={labelColumns} required={false} setFieldValue={setFieldValue} inputType={FormInputType.String} />
                    </>
                )}
            </>
        )}
    </>)
};

const ValidationScheme = Yup.object<IArtifact>().shape({
    artifactType: Yup.number()
        .required('Artifact Type is required')
        .oneOf([1, 2, 3, 4], 'Artifact Type is required'),
    displayName: Yup.string().required('Display Name is required').nullable(),
    description: Yup.string().required('Description is required').nullable(),
    domainTagsCsv: Yup.string().required('A domain tag is required').nullable(),
    subjectTagsCsv: Yup.string().required('A subject tag is required').nullable(),
    dataset: Yup.string().when('artifactType', {
        is: (val: number) => val === ArtifactType.Dataset,
        then: Yup.object().shape({
            databaseConnectionId: Yup.string()
                .required('Dataset Connection is required')
                .nullable(),
            datasetView: Yup.string()
                .required('Dataset View is required')
                .nullable(),
        }),
    }),
    microStrategy: Yup.object().when('artifactType', {
        is: (val: number) => val === ArtifactType.MicroStrategy,
        then: Yup.object().shape({
            reportId: Yup.string().required('Report Id is required').nullable(),
        }),
    }),
    url: Yup.object().when('artifactType', {
        is: (val: number) => val === ArtifactType.Url,
        then: Yup.object().shape({
            reportUrl: Yup.string()
                .url("Report Url is not a valid url")
                .required('Report Url is required')
                .nullable(),
        }),
    }),
    ssrs: Yup.object().when('artifactType', {
        is: (val: number) => val === ArtifactType.Ssrs,
        then: Yup.object().shape({
            reportPath: Yup.string()
                .required('Report Path is required')
                .nullable(),
            reportName: Yup.string()
                .required('Report Name is required')
                .nullable(),
        }),
    }),
});

interface IFormikInfo {
    item: IArtifact;
    onCancel: () => void;
    onSave: (artifact: IArtifact) => void;
}

const labelColumns: number = 2;

const ArtifactFormik: React.FunctionComponent<IFormikInfo> = ({
    item,
    onCancel,
    onSave,
}) => {
    const setArtifact = (artifact: IArtifact) => {
        onSave(artifact);
    };

    return (
        <OEFormikModal
            item={item}
            labelColumns={labelColumns}
            title={`${item.id > 0 ? `Edit Artifact: ${item.name}` : `Add New Artifact`}`}
            progressMessage={ProgressMessages.Artifact}
            successMessage={SuccessMessages.Artifact}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={setArtifact}
        />
    );
};

export default ArtifactFormik;