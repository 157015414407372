import { useEffect, useRef, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../../core/components/messaging/entities/Notification';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import OENotification from '../../../core/components/messaging/OENotification';
import { navigateToPage } from '../../../core/utilities/URL';
import { ISessionTimer } from '../../../site/entities/SessionTimer';
import { getSiteSetting, SiteSetting } from '../../../site/entities/SiteSettings';
import { useMSKeepAlive } from '../../services/MicrostrategyService';
declare global { var msLogout: any; }

interface IComponentInfo {
    keepAlive: boolean;
    setKeepAlive: (b: boolean) => void;
}

const MSSessionController: React.FunctionComponent<IComponentInfo> = ({ keepAlive, setKeepAlive }) => {
    const { service: keepAliveService, doRefresh } = useMSKeepAlive();

    const timerIdRef = useRef<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [sessionTimeout] = useState<number>(getSiteSetting(SiteSetting.MSSessionTimeout) * 60);
    const [sessionWarning] = useState<number>(getSiteSetting(SiteSetting.SessionWarning) * 60);
    const [sessionTimer, setSessionTimer] = useState<ISessionTimer>({ seconds: -1, isActive: false, initialized: false, });
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        if (sessionTimeout > 0 && sessionWarning < sessionTimeout && keepAlive && sessionTimeout > 0 && sessionTimer.isActive) {
            setTimer(sessionTimeout);
        }
        keepAlive && setKeepAlive(false);
        // eslint-disable-next-line
    }, [keepAlive]);


    useEffect(() => {
        return () => {
            window.clearInterval(timerIdRef.current);
            timerIdRef.current = 0;
        }
    }, [])

    useEffect(() => {
        setTimer(sessionTimeout);
        // eslint-disable-next-line
    }, [sessionTimeout])

    const startTimer = () => {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = window.setInterval(() => setTimer((timer) => timer - 1), 1000);
    }

    useEffect(() => {
        if (sessionTimeout > 0 && sessionWarning < sessionTimeout) {
            setSessionTimer({
                seconds: sessionTimeout * 60,
                isActive: true,
                initialized: false,
            });
            startTimer();
        }
        // eslint-disable-next-line
    }, [sessionTimeout]);

    useEffect(() => {
        if (keepAliveService.success) {
            if (keepAliveService.body.toString() === '204') {
                setSessionTimer({
                    seconds: sessionTimeout * 60,
                    isActive: true,
                    initialized: false,
                });
                setTimer(sessionTimeout);
            }
            else {
                navigateToPage('/home');
            }
            startTimer();
        }
        // eslint-disable-next-line
    }, [keepAliveService]);

    useEffect(() => {
        if (sessionTimeout > 0 && sessionWarning < sessionTimeout && sessionTimer.seconds > 0) {
            if (timer < 0) {
                navigateToPage('/home');
            }
            else if (timer <= sessionWarning && sessionTimer.isActive) {
                setSessionTimer({ ...sessionTimer, isActive: false });
                window.setTimeout(() => {
                    setConfirmation({
                        ...defaultConfirmationMessage,
                        setConfirmation, show: true,
                        title: 'Session About to Expire',
                        message: `<p><b>Your session will end in  ${timer > 0 ? timer : 0} seconds due to inactivity</b></p><p>As a security precaution, if there is no additional activity in your online session,the session will end and you will be automatically logged out</p><p>If you are still working in your online session, choose OK to continue.</p>`,
                        onOk: extendSession,
                    });
                }, 0); // this setTimeout is a workaround cause the font awesome icon to display when changed
            }
            if (!sessionTimer.initialized) {
                setSessionTimer({ ...sessionTimer, initialized: true });
            }
        }
        // eslint-disable-next-line
    }, [timer]);

    const extendSession = (s?: boolean) => {
        !s && setConfirmation({ ...defaultConfirmationMessage });
        setSessionTimer({ ...sessionTimer, isActive: true });
        doRefresh();
    };

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OENotification setNotification={setNotification} notification={notification} />
        </>
    );
};


export default MSSessionController;
