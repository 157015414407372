import React, { Dispatch, SetStateAction } from 'react';
import OEAutoComplete from '../../../core/components/form/OEAutoComplete';
import OEDropdown, { IDropdownValues } from '../../../core/components/form/OEDropdown';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';
import { ISchoolOptionsSelection } from '../pages/Dashboard';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';

type SubheaderProps = {
    year: string;
    academicYears: IDropdownValues[];
    setYear: Dispatch<SetStateAction<string>>;
    schoolDistricts: ISchoolOptionsSelection[];
    onNavigate: (value: string) => void;
    onSearch: (value: string) => void;
    inputValue: string;
    routeProperties?: ICustomRouteProperties;
}

export const Subheader: React.FunctionComponent<SubheaderProps> = ({
    year,
    academicYears,
    setYear,
    schoolDistricts,
    onNavigate,
    onSearch,
    inputValue,
    routeProperties
}) => {

    const typeScreen = useResponsivenessContext()
    return (
        <>
            <div className="subheader" style={styles.container}>
                <h3 style={styles.title}>School Report Card</h3>
                <div style={styles.content}>
                    <div style={styles.flex}>
                        <div
                            className={`${typeScreen === "mobile" ? 'm-t-5' : ''} container-dropdown-data `} >
                            <p>School Year</p>
                        </div>
                        <div style={{ width: 120 }}>
                            <OEDropdown
                                values={academicYears}
                                value={year}
                                onChange={setYear}
                                className='dropdown-header-year'
                            />
                        </div>
                    </div>
                    <div style={styles.searchContainer} className={'m-l-10'}>
                        <div className={`${typeScreen === "mobile" ? 'm-t-5' : ''} container-dropdown-data `} >
                            <p>School or district name</p>
                        </div>
                        <div style={{ flex: 2 }}>
                            <OEAutoComplete
                                options={schoolDistricts}
                                style={{
                                    width: "100%",
                                    borderRadius: 50,
                                    height: 35,
                                    textAlign: 'left'
                                }}
                                onNavigate={onNavigate}
                                onSearch={onSearch}
                                placeholder="Search by school or district name"
                                value={inputValue}
                                titleClassName='title-search-layout'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {routeProperties && (<div className="subheader" style={{ display: 'flex', alignItems: 'center' }}>
                <p className='top-left-text'>{routeProperties.pageName}</p>
            </div>
            )}
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#E4F3ED',
        padding: 10,
        height: 'auto !important'
    } as React.CSSProperties,
    title: {
        color: '#206A6C',
        fontWeight: 300
    } as React.CSSProperties,
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: "row"
    } as React.CSSProperties,
    flex: {
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    searchContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    } as React.CSSProperties
};
