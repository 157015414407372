export interface IBreadcrumb {
    id: any;
    name: string;
    icon?: string;
    url?: string;
    parentId?: any;
    onNavigate?: Function;
    hidden?: boolean;
}

export const defaultBreadcrumb: IBreadcrumb = {
    id: -1, name: '', icon: '', hidden: false
};

