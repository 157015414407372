/* eslint-disable react/jsx-no-undef */
import React, { Fragment, useEffect, useState } from 'react';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { IReportGroup } from '../../../reporting/entities/ReportGroup';
import { IMicroStrategyDossierChapter, IMicroStrategyDossierChapterList } from '../../entities/MicrostrategyDossierChapter';
import { getDossierFilters } from '../../services/MicrostrategyDossierService';
import MicrostrategyPageAbout from './MicrostrategyPageAbout';
import MicrostrategyPageSection from './MicrostrategyPageSection';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';
declare global { var msLogin: any; }

interface ISortedItem {
    sort: number;
    index: number;
}

interface IComponentInfo {
    chapterList: IMicroStrategyDossierChapterList;
    dossier: any;
    reportID: string;
    onUpdateFilters?: (p: any) => void;
    initFilters?: () => void;
    setNotification?: (n: INotification) => void;
    onPageChanged?: () => void;
    aboutPages?: IReportGroup[];
    aboutIndex: number;
    setAboutIndex: (i: number) => void;
    setCurrentChapterIndex?: (i: number) => void;
    tabs: boolean;
}

export const MicrostrategyPageChapters: React.FunctionComponent<IComponentInfo> = ({
    reportID, aboutIndex, setCurrentChapterIndex, setAboutIndex, chapterList, dossier, onUpdateFilters,
    onPageChanged, aboutPages, tabs
}) => {
    const [currentIndex, setCurrentIndex] = useState<string>('0-0');
    const [sortList, setSortList] = useState<ISortedItem[]>([]);
    const typeScreen = useResponsivenessContext()

    const onIndexChanged = (i: string) => {
        if (currentIndex !== i) {
            setCurrentIndex(i);
            setAboutIndex(-1);
            onPageChanged?.();
            setCurrentChapterIndex?.(parseInt(i.split('-')[0], 10));
        }
    }

    const onAboutChanged = (i: number) => {
        if (aboutIndex !== i) {
            setCurrentIndex('');
            setAboutIndex(i);
        }
    }

    useEffect(() => {
        if (chapterList.chapters.length > 0) {
            const i: ISortedItem[] = [];
            for (const c of chapterList.chapters) {
                if (!c.hidden && !c.suppressed) {
                    i.push({ sort: c.sort, index: c.index });
                }
            }
            if (i.length > 0) {
                i.sort(function (x, y) {
                    return x.sort - y.sort;
                });
                setSortList(i);
                setCurrentIndex(`${i[0].index}-0`);
                setCurrentChapterIndex?.(i[0].index);
                gotoPage(chapterList.chapters[i[0].index], 0);
            }
        }
        // eslint-disable-next-line
    }, [chapterList]);

    const gotoPage = (chapter: IMicroStrategyDossierChapter, pageIndex: number) => {
        dossier.navigateToPage(chapter.getFirstPage());
        let index: number = 0;
        while (index < pageIndex) {
            dossier.goToNextPage();
            index = index + 1;
        }
    }

    useEffect(() => {
        onUpdateFilters && getDossierFilters(onUpdateFilters, dossier);
        // eslint-disable-next-line
    }, [dossier]);

    useEffect(() => {
        setCurrentIndex('0-0');
        // eslint-disable-next-line
    }, [reportID]);

    return (
        <>
            {chapterList.totalPages > 1 && (
                <>
                    <nav
                        className={`${(tabs)
                            ? 'fst-italic small nav nav-tabs m-t-10 ' : typeScreen === "mobile"
                                ? '' : 'pull-right'} m-b-0 mb-3`}
                        role="tablist"
                    >
                        {sortList.length > 1 && sortList.map((c, index) =>
                            <Fragment key={index} >
                                {chapterList.chapters[c.index].children.map((p, index2) =>
                                    <MicrostrategyPageSection
                                        page={p}
                                        chapter={chapterList.chapters[c.index]}
                                        currentIndex={currentIndex}
                                        pageIndex={0}
                                        key={index2}
                                        chapterIndex={c.index}
                                        setCurrentIndex={onIndexChanged}
                                        dossier={dossier}
                                        isLink={!tabs}
                                        gotoPage={gotoPage}
                                    />
                                )
                                }
                            </Fragment>
                        )}
                        {aboutPages?.map((i, index) =>
                            <MicrostrategyPageAbout
                                currentIndex={index}
                                aboutIndex={aboutIndex}
                                key={index}
                                setAboutIndex={onAboutChanged}
                                item={i}
                                isLink={!tabs}
                            />

                        )}

                    </nav>
                    {tabs && (
                        <div className="m-b-10" />
                    )}
                </>
            )}
        </>
    );
};


export default MicrostrategyPageChapters;

