import React, { CSSProperties } from 'react';
import { OEFormControl } from './OEForm';

const getColumnStyle = (width?: string): CSSProperties => {
    return {
        width: `${width}`
    };
};

export enum OEInputType {
    Number = 'number',
    Text = 'text',
    LargeText = 'largetext',
    Password = 'password',
    Radio = 'radio'
}

export interface IOEInputInfo {
    className?: string;
    value?: any;
    name?: string;
    onChange?: (content: any) => void;
    setFieldValue?: (id: string, data: any) => void;
    type?: string;
    submit?: () => void;
    placeholder?: string;
    disabled?: boolean;
    rows?: number;
    cols?: number;
    maxLength?: number;
    autoFocus?: boolean;
    autoComplete?: string;
    min?: number;
    max?: number;
    ref?: any;
    inline?: boolean;
    width?: string;
    onClick?: () => void;
    accept?: string;
    style?: CSSProperties;
}

const OEInput: React.FunctionComponent<IOEInputInfo> = ({
    value, name, onChange, className, setFieldValue, type, submit,
    placeholder, disabled, rows, cols, maxLength, autoFocus, autoComplete, min, max, ref,
    width, inline, onClick, accept, style
}: IOEInputInfo) => {

    const handleChange = (event: any) => {
        let v = event.target.value;
        if (getType() === 'number') {
            v = v.toString() === '' ? 0 : v;
        }
        name && setFieldValue?.(name, v);
        onChange?.(v);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submit?.();
        }
    };

    const getType = (): string => {
        // tslint:disable
        switch (type) {
            case OEInputType.Number:
                return 'number';

            default:
                return type || '';
        }
        // tslint:enable
    };

    const isLargeText = (): boolean => {
        return type === OEInputType.LargeText;
    };

    return (
        < >
            {isLargeText() && (
                <textarea
                    onClick={onClick}
                    onChange={handleChange}
                    className={`form-control ${className || ''}`}
                    rows={rows}
                    cols={cols}
                    disabled={disabled}
                    name={name}
                    value={value || ''}
                    placeholder={placeholder}
                    style={style}
                />
            )}

            {!isLargeText() && (
                <OEFormControl
                    style={style || getColumnStyle(width)}
                    value={value || ''}
                    onChange={handleChange}
                    className={className}
                    type={getType()}
                    ref={ref}
                    onClick={onClick}
                    id={name}
                    onKeyPress={handleKeyDown}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    autoComplete={autoComplete}
                    min={min}
                    max={max}
                    accept={accept}
                />
            )}
        </>
    );
};

export default OEInput;