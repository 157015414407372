import React, { useRef, useState } from 'react';

import { AutoComplete } from 'antd';
import { CSSProperties } from 'styled-components';
import { useOutsideAlerter } from '../../hooks/useOnClickOutside';
import { useResponsivenessContext } from '../../contexts/Resposiveness';

export interface IAutoCompleteOptionSelections {
    value: string;
    label?: any;
    options?: any;
}

interface IComponentInfo {
    options: IAutoCompleteOptionSelections[];
    onNavigate: (i: string) => void;
    onSearch: (i: string) => void;
    style?: CSSProperties;
    value: string;
    placeholder: string;
    title?: string;
    titleClassName?: string;
    ariaLabel?: string;
    popupMatchSelectWidth?: number;
}

const OEAutoComplete: React.FunctionComponent<IComponentInfo> = ({
    options, style, title, onNavigate, onSearch, popupMatchSelectWidth,
    value, placeholder, ariaLabel, titleClassName
}) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const wrapperRef = useRef(null)
    const typeScreen = useResponsivenessContext()

    const mustShow = () => {
        if (typeScreen === "mobile") {
            return options.length > 0 && isOpen;
        } else {
            // Default behavor for non mobile screens
            return undefined
        }
    };

    useOutsideAlerter(wrapperRef, () => {
        setIsOpen(false)
    });

    return (
        <>
            {title && <p className={titleClassName ?? ''}>{title}</p>}
            <div style={{ position: 'relative' }} ref={wrapperRef}>
                <AutoComplete
                    options={options}
                    onSelect={onNavigate}
                    onSearch={(text) => {
                        if (text.length < 3) setIsOpen(false)
                        onSearch(text)
                    }}
                    value={value}
                    placeholder={placeholder}
                    style={style}
                    popupMatchSelectWidth={popupMatchSelectWidth}
                    variant="filled"
                    open={mustShow()}
                >
                    <input
                        role="presentation"
                        title={placeholder}
                        className="search-input"
                        aria-label={ariaLabel}
                        placeholder={placeholder}

                    />
                </AutoComplete>
                <div
                    className="search-icon-container"
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                >
                    <i
                        className="fa fa-search"
                        style={{
                            transform: 'rotate(90deg)',
                            height: 20,
                            width: 20,
                            color: 'black',
                        }}
                    />
                </div>
            </div >
        </>
    );
};

export default OEAutoComplete;
