import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect, IDropdownValues } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEForm from '../../../core/components/formik/OEForm';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormEditor from '../../../core/components/formik/OEFormEditor';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEFormItem from '../../../core/components/formik/OEFormItem';
import OEHeading from '../../../core/components/general/OEHeading';
import OEIcon from '../../../core/components/general/OEIcon';
import OEModal, { ModalSize, OEModalBody } from '../../../core/components/general/OEModal';
import OEWell from '../../../core/components/general/OEWell';
import OEMessage from '../../../core/components/messaging/OEMessage';
import { Icon } from '../../../core/entities/Icon';
import { getReportConfigurationDefaultValue, getReportConfigurationDisplayValue, IReportConfigurationItem, ReportConfigurationDataType, ReportConfigurationTypes } from '../../entities/ReportConfiguration';
import { ReportIconBaseURL } from '../../entities/ReportIcons';
import IconSelector from './IconSelector';

const labelColumns: number = 12;

enum ViewTypes {
    Default = 1,
    Select
}

interface IFormInfo {
    lookupTables: IReportConfigurationItem[];
}

const Form: React.FunctionComponent<FormikProps<IReportConfigurationItem> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Default);
    const [label, setLabel] = useState<string>('Value');
    const [label2, setLabel2] = useState<string>('Value');
    const [dropdownValues, setDropDownValues] = useState<IDropdownValues[]>([]);

    useEffect(() => {
        try {
            const r = ReportConfigurationTypes.find(q => q.id === values.id);
            if (r) {
                setLabel(r.label);
                setLabel2(r.label2);
                setDropDownValues(r.values);
            }
        }
        catch {
            setLabel('Value');
            setLabel2('Value');
        }
        // eslint-disable-next-line
    }, [values.id]);

    const setConfigurationType = (i: any) => {
        const s: IReportConfigurationItem = lookupTables.filter(q => q.id === i)[0];
        setFieldValue('id', s.id);
        setFieldValue('dataType', s.dataType);
        setFieldValue('name', s.name);
        setFieldValue('helpText', s.helpText);
        setFieldValue('value', getReportConfigurationDefaultValue(s.id));
        setFieldValue('value2', '');
    }

    const setDisplayValue = (i: any) => {
        const s: IReportConfigurationItem = lookupTables.filter(q => q.id === values.id)[0];
        setFieldValue('displayValue', getReportConfigurationDisplayValue({ ...s, value: i }));
    }

    const setDisplayValue2 = (i: any) => {
        const s: IReportConfigurationItem = lookupTables.filter(q => q.id === values.id)[0];
        setFieldValue('displayValue', getReportConfigurationDisplayValue({ ...s, value2: i }));
    }

    const onCancel = () => {
        setShowView(ViewTypes.Default);
    };

    const onShowSelect = () => {
        setShowView(ViewTypes.Select);
    };

    const onSelectIcon = (i: string) => {
        setFieldValue('value', i);
        setShowView(ViewTypes.Default);
    };

    return (
        <>
            <OEFormDropdown
                label={'Type'} name="id" value={values.id}
                alwaysShowDefault={true} defaultSelect={defaultDropdownSelect}
                errors={errors} touched={touched} columns={2} values={lookupTables}
                onChange={setConfigurationType} required={true}
            />

            {values.dataType !== ReportConfigurationDataType.None && (
                <>

                    <OEMessage message={values.helpText} hideDismissable={true} />
                    <hr />

                    {values.dataType === ReportConfigurationDataType.Dropdown && (
                        <OEFormDropdown
                            label={label} name="value" value={values.value}
                            defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                            errors={errors} touched={touched} columns={labelColumns}
                            values={dropdownValues} setFieldValue={setFieldValue}
                            onChange={setDisplayValue} required={true}
                        />
                    )}

                    {values.dataType === ReportConfigurationDataType.DropdownText && (
                        <>
                            <OEFormDropdown
                                label={label} name="value" value={values.value}
                                defaultSelect={defaultDropdownSelect} alwaysShowDefault={false}
                                errors={errors} touched={touched} columns={labelColumns}
                                values={dropdownValues} setFieldValue={setFieldValue}
                                onChange={setDisplayValue} required={true}
                            />
                            <OEFormInput
                                label={label2} name="value2" value={values.value2} required={true}
                                onChange={setDisplayValue2}
                                errors={errors} touched={touched} columns={labelColumns}
                                setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                            />
                        </>
                    )}

                    {values.dataType === ReportConfigurationDataType.String && (
                        <OEFormInput
                            label={label} name="value" value={values.value} required={true}
                            errors={errors} touched={touched} columns={labelColumns}
                            setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                        />
                    )}

                    {values.dataType === ReportConfigurationDataType.Icon && (
                        <div onClick={onShowSelect} >
                            <OEFormItem columns={labelColumns} name="value" label="Icon" errors={errors} touched={touched} required={true}>
                                <OEIcon icon={Icon.Search} className="p-t-10 pull-right" />
                                <OEWell className="m-t-0">
                                    <img alt={values.value} className="report-icon m-r-5" src={`${ReportIconBaseURL}${values.value}`} ></img>
                                </OEWell>
                            </OEFormItem>

                        </div>

                    )}

                    {values.dataType === ReportConfigurationDataType.Integer && (
                        <OEFormInput
                            label={label} name="value" value={values.value} required={true}
                            errors={errors} touched={touched} columns={labelColumns}
                            setFieldValue={setFieldValue} inputType={FormInputType.Number}
                        />
                    )}

                    {values.dataType === ReportConfigurationDataType.Boolean && (
                        <OEFormBoolean
                            label={label} name="value" value={values.value} required={true}
                            errors={errors} touched={touched} columns={labelColumns}
                            setFieldValue={setFieldValue}
                        />
                    )}

                    {values.dataType === ReportConfigurationDataType.Html && (
                        <OEFormEditor
                            label={label} name="value" value={values.value} required={true}
                            errors={errors} touched={touched} columns={labelColumns}
                            setFieldValue={setFieldValue}
                        />
                    )}
                    <hr />
                </>
            )}

            {showView === ViewTypes.Select && (
                <IconSelector
                    onCancel={onCancel}
                    onSelect={onSelectIcon}
                />
            )}

        </>
    );
};

const ValidationScheme = Yup.object<IReportConfigurationItem>().shape({
    value: Yup.string().required('Value is required').nullable(),
});

interface IFormikInfo {
    item: IReportConfigurationItem;
    isEditing: boolean;
    onCancel: () => void;
    onSubmit: (i: IReportConfigurationItem) => void;
    configuration: IReportConfigurationItem[];
}

const ConfigurationFormik: React.FunctionComponent<IFormikInfo> = ({ item, isEditing, onCancel, onSubmit, configuration }) => {

    const [localValues] = useState(item);

    const getForm = (props: FormikProps<any>, p: boolean) => (
        <OEForm {...props}
            onCancel={onCancel}
            component={Form}
            submitText="Submit"
            cancelText="Cancel"
            lookupTables={configuration}
            labelColumns={2}
        />
    );

    return (
        <OEModal show={true} oeSize={ModalSize.Medium} onHide={onCancel}>
            <OEModalBody className="alert-light">
                <OEHeading size={3} text="Add Report Configuration" />
                <Formik
                    onSubmit={onSubmit}
                    initialValues={localValues}
                    enableReinitialize={true}
                    validationSchema={ValidationScheme}
                >
                    {(props) => getForm(props, isEditing)}
                </Formik>

            </OEModalBody>
        </OEModal >
    );
};

export default ConfigurationFormik;
