/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { IMicroStrategyDossierChapter, IMicroStrategyDossierPage } from '../../entities/MicrostrategyDossierChapter';
import { setDossierSelectedPage } from '../../services/MicrostrategyDossierService';
declare global { var msLogin: any; }

interface IComponentInfo {
    page: IMicroStrategyDossierPage;
    dossier: any;
    pageIndex: number;
    chapterIndex: number;
    chapter: IMicroStrategyDossierChapter;
    currentIndex: string;
    setCurrentIndex: (i: string) => void;
    gotoPage: (chapter: IMicroStrategyDossierChapter, pageIndex: number) => void;
    isLink: boolean;
}

const MicrostrategyPageSection: React.FunctionComponent<IComponentInfo> = ({
    chapter, page, isLink, setCurrentIndex, currentIndex, dossier, pageIndex,
    chapterIndex, gotoPage
}) => {

    const onSelect = () => {
        setDossierSelectedPage(dossier, chapterIndex, pageIndex, onChapterSet);
    }

    const onChapterSet = () => {
        setCurrentIndex(`${chapterIndex}-${pageIndex}`);
        gotoPage(chapter, pageIndex);
    }

    return (
        <span>
            <button role="tab" data-rb-event-key={`group`} onClick={onSelect}
                aria-controls={`reports-tabpane-group`} aria-selected="true"
                className={`pagination-link pagination-link-button ${currentIndex === `${chapterIndex}-${pageIndex}` ? 'pagination-link-active' : ''}`}>
                <span id={page.name} >{`${page.name}`}</span>
            </button>
        </span>
    );
};

export default MicrostrategyPageSection;

